import { useState, useEffect } from "react";
import axios from "axios";
import { Nav, Navbar, NavDropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../actions/authActions";
import routers from "../helpers/routers";
import { PROGRAMA_ALUMNO_ENDPOINT } from "../helpers/endpoints";
import {
  carreraAlumnoSeleccionada,
  cleanCarrera,
} from "../actions/carreraActions";

export default function Navigation() {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);
  const [carreras, setCarreras] = useState([]);
  const [carreraElegida, setCarreraElegida] = useState([]);
  const carreraSect = useSelector((state) => state.carr.carreraSect);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const programasAlumno = () => {
    if (!loading) {
      if (carreras.length == 1) {
        return (
          <Nav.Link as={NavLink} to="/#">
            {carreraSect?.descripcion}
          </Nav.Link>
        );
      } else {
        return (
          <NavDropdown title={carreraSect?.descripcion} id="menu-carreras">
            {menuCarrera()}
          </NavDropdown>
        );
      }
    } else {
      return (
        <Nav.Link as={NavLink} to="/#">
          cargado carreras....
        </Nav.Link>
      );
    }
  };

  const menuCarrera = () => {
    //filtro la carrera activa par no mostrara en la seleccion
    const carr = carreras?.filter(
      (c) => c?.alumnoPrograma != carreraSect?.alumnoPrograma
    );

    return carr?.map((c) => (
      <NavDropdown.Item
        key={c.alumnoPrograma}
        onClick={() => dispatch(carreraAlumnoSeleccionada(c))}
      >
        {c.descripcion}
      </NavDropdown.Item>
    ));
  };

  useEffect(() => {
    return new Promise((resolve, reject) => {
      user.firstName &&
        axios
          .get(PROGRAMA_ALUMNO_ENDPOINT, {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            setCarreras(response.data);
            setCarreraElegida(
              dispatch(carreraAlumnoSeleccionada(response.data[0]))
            );
            setLoading(false);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            reject(error);
          });
    });
  }, [user]);

  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand as={NavLink} to={routers.root} className="ml-3">
        <img src="../assets/logo-udemm.png" className="logoHeader" />
      </Navbar.Brand>
      {loggedIn ? (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Inscripciones">
                <NavDropdown.Item
                  as={NavLink}
                  to={routers.inscripcionesMaterias}
                >
                  Materias
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={routers.inscripcionesFinales}
                >
                  Finales
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Consultas">
                <NavDropdown.Item as={NavLink} to={routers.consultasNotas}>
                  Consulta de notas - emisión de cert exámen
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to={routers.consultaMaterias}>
                  Histórico de Inscripciones a Materias
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to={routers.finalesHistorico}>
                  Inscripciones a Finales
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={routers.consultaFechasExamenes}
                >
                  Fechas de exámenes
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={NavLink} to={routers.tramite}>
                Trámites
              </Nav.Link>

              <Nav.Link as={NavLink} to={routers.pagos}>
                Pagos
              </Nav.Link>

              {programasAlumno()}
            </Nav>

            <Nav className="mr-3">
              <NavDropdown
                title={user.firstName + ", " + user.lastName}
                id="menu-dropdown"
              >
                <NavDropdown.Item as={NavLink} to={routers.miCuenta}>
                  Mi cuenta
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={routers.blanqueoClaveSinParametro}
                >
                  Cambiar <br /> contraseña
                </NavDropdown.Item>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <NavDropdown.Item
                  onClick={() =>
                    dispatch(logoutUser(), dispatch(cleanCarrera()))
                  }
                >
                  Cerrar sesíon
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </>
      ) : (
        <Nav></Nav>
      )}
    </Navbar>
  );
}
