import axios from 'axios';

import {RECAPTCHA_KEY} from "./constantes";

const setAuthToken = token => {

    if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer '+token;
       
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}

/*export  const optenerRecaptcha = async action => {
    const recaptcha = await load(RECAPTCHA_KEY);
    const token = await recaptcha.execute(action);
    return token;
};*/
export default setAuthToken;