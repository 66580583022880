import { Container, Row, Col, Card, Spinner } from "react-bootstrap";



export default function PersonaDatos({ persona, registro }) {



  return (
    <div>
      <Card className="border-primary shadow">
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="card-header">
              <h3 className="panel-title">Persona</h3>
            </div>
          </div>
          <div className="panel-body">
            <Container>
              {(!persona) ?
                (<Spinner animation="grow" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>)
                :
                <>
                  <Row className="mt-3 text-left">
                    <Col>
                      Nombre: <b> {persona?.nombre}</b>
                    </Col>
                    <Col>
                      Apellido: <b>{persona?.apellido}</b>
                    </Col>
                    <Col>Numero Legajo: <b>{persona?.legajo}</b></Col>
                  </Row>
                  <Row className="mt-3 mb-3  text-left">
                    <Col>
                      Tipo Doc: <b>{persona?.tipoDocumento}</b>
                    </Col>
                    <Col>
                      Numero: <b>{persona?.numeroDocumento}</b>
                    </Col>
                    <Col>
                      Fecha Nacimiento: <b>{persona?.fechaNacimiento}</b>
                    </Col>
                  </Row>
                </>
              }

            </Container>
          </div>
        </div>
      </Card>



    </div>
  )
}
