import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { Card, Col, Row } from "react-bootstrap";

export default function Home() {
  return (
    <Card className="shadow mr-5  ml-5 mt-5 p-3">
      <Row>
        <Col>
          <Card className=" mt-2 text-center" border="primary">
            <Card.Header as="h5">Título</Card.Header>
            <Card.Body>
              <Card.Title>Título secundario</Card.Title>
              <Card.Text>Mensaje al aumno.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className=" mt-2 text-center" border="danger">
            <Card.Header as="h5">Título</Card.Header>
            <Card.Body>
              <Card.Title>Título secundario</Card.Title>
              <Card.Text>Mensaje al aumno.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Card>
  );
}
