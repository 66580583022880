import axios from "axios";


export const helpHttp = () => {


  const customFetch = async ( config) => {
    config.method = config.method || "GET";
    let response
    try {
      response = await axios(config);
      return response;
    } catch (error) {

      if (error.response.status===undefined || error.response.status===null) {
        error = { message: "" }
      }else{
        error.message === "Network Error" || error.response?.status === 500 ?
          error = { message: "" }
          : error = { message: error.response.data.message }
      }
      return Promise.reject(error);
    }

  };

  const get = (url, options = {}) => {

    const config = {
      url: url,
    };

    return customFetch( config);
  }

  const post = (url, options = {}) => {
    const config = {
      method: "POST",
      url: url,
      data: options?.data,
    };

    return customFetch(config);
  };

  const put = (url, options = {}) => {
    const config = {
      method: "PUT",
      url: url,
      data: options?.data,
    };
    return customFetch( config);
  };

  const del = (url, options = {}) => {
    options = { method: "DELETE", };
    return customFetch( options);
  };

  return {
    get,
    post,
    put,
    del,
  };
};