import InscripcionACursosTabla from "./InscripcionACursosTabla";
import { Button, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  INSCRIPCION_CURSO_ENDPOINT,
  INSCRIPCION_CURSO_PDF_ENDPOINT,
} from "../../../helpers/endpoints";
import { useSelector, useDispatch } from "react-redux";
import { setMessageAction } from "../../../actions/messageActions";
import { helpHttp } from "../../../helpers/helpAxios";
import swal from "sweetalert2";
import {
  BsArrowReturnRight,
  BsFillTrashFill,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { cursoEstado } from "../../../helpers/constantes";
import { axiosDownloadFile } from "../../../helpers/axiosDownloadFile";

const regIncripcion = (dato) => {
  return { ...dato, btnText: "Inscribir", disabled: false };
};
const regEliminar = (dato) => {
  return { ...dato, btnText: "Eliminar", disabled: false };
};

export default function InscripcionCursos() {
  const [materiasDisponibles, setMateriasDisponibles] = useState(null);
  const [materiasInscripto, setMateriasInscripto] = useState(null);
  const carreraSect = useSelector((state) => state.carr.carreraSect);
  const dispatch = useDispatch();

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const getMateriasIncripcion = () => {
    helpHttp()
      .get(INSCRIPCION_CURSO_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => {
        let disponible = resp.data.filter(
          (d) => d.accion === cursoEstado.DISPONIBLE
        );
        setMateriasDisponibles(disponible.map((d) => regIncripcion(d)));
        let inscripto = resp.data.filter(
          (d) => d.accion != cursoEstado.DISPONIBLE
        );
        setMateriasInscripto(inscripto.map((d) => regEliminar(d)));
      })
      .catch((error) => {
        setMateriasDisponibles([]);
        setMateriasInscripto([]);
        mensajeSistema(error.message, "ERROR");
      });
  };

  const deleteMateriasIncripcion = ({ cursoSelected }) => {
    let seleccion = {
      ...cursoSelected.data,
      btnText: "Proce...",
      disabled: true,
    };

    swal
      .fire({
        title: `Materia: ${cursoSelected.data.materia.descripcion}`,
        text: `En el horario: ${cursoSelected.data.horariosMateria} `,
        icon: "question",
        iconHtml: "؟",
        buttons: true,
        dangerMode: true,
        confirmButtonText: `Eliminar curso`,
        cancelButtonText: "Mejor mas tarde",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setMateriasInscripto((prev) =>
            materiasInscripto.map((item) => {
              if (item.id === seleccion.id) return seleccion;
              else return item;
            })
          );

          const curso = {
            curso: seleccion.id,
            alumnoPrograma: carreraSect.alumnoPrograma,
            materia: seleccion.materia.materiaId,
            cursoTipoClase: seleccion.cursoTipoClase,
          };
          console.log(JSON.stringify(curso, 0, 2));
          helpHttp()
            .put(INSCRIPCION_CURSO_ENDPOINT, { data: curso })
            .then((resp) => {
              setMateriasInscripto((materiasInscripto) =>
                materiasInscripto.filter((m) => m.id !== seleccion.id)
              );
              setMateriasDisponibles((prev) => [
                ...prev,
                regIncripcion(resp.data),
              ]);
            })
            .catch((error) => {
              setMateriasInscripto((mesasFinalesInscripto) =>
                mesasFinalesInscripto.filter((m) => m.id !== seleccion.id)
              );
              setMateriasInscripto((prev) => [...prev, regEliminar(seleccion)]);
              mensajeSistema(error.message, "ERROR");
            });
        }
      });
  };

  const postMateriasIncripcion = ({ cursoSelected }) => {
    let seleccion = {
      ...cursoSelected.data,
      btnText: "Proce...",
      disabled: true,
    };
    swal
      .fire({
        title: `Materia: ${cursoSelected.data.materia.descripcion}`,
        text: `En el horario: ${cursoSelected.data.horariosMateria} `,
        icon: "question",
        iconHtml: "؟",
        buttons: true,
        dangerMode: true,
        confirmButtonText: `Si`,
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setMateriasDisponibles((prev) =>
            materiasDisponibles.map((item) => {
              if (item.id === seleccion.id) return seleccion;
              else return item;
            })
          );

          helpHttp()
            .post(
              INSCRIPCION_CURSO_ENDPOINT + "/" + carreraSect?.alumnoPrograma,
              { data: seleccion }
            )
            .then((resp) => {
              setMateriasDisponibles((materiasInscripto) =>
                materiasInscripto.filter((m) => m.id !== seleccion.id)
              );
              setMateriasInscripto((prev) => [...prev, regEliminar(resp.data)]);
              mensajeSistema(
                "Se encuentra inscripto a " + resp.data.materia.descripcion,
                "success"
              );
            })
            .catch((error) => {
              setMateriasDisponibles((materiasDisponibles) =>
                materiasDisponibles.filter((m) => m.id !== seleccion.id)
              );
              setMateriasDisponibles((prev) => [
                ...prev,
                regIncripcion(seleccion),
              ]);
              mensajeSistema(error?.message, "ERROR");
            });
        }
      });
  };

  useEffect(() => {
    setMateriasDisponibles(null);
    setMateriasInscripto(null);
    carreraSect && getMateriasIncripcion();
  }, [carreraSect]);

  const pdf = () => {
    axiosDownloadFile(
      INSCRIPCION_CURSO_PDF_ENDPOINT + "/" + carreraSect?.alumnoPrograma,
      "cursosInscriptos"
    );
  };

  return (
    <>
      <Card className="shadow m-5 p-3">
        <h2 className="text-center m-3 ">Materias disponible</h2>
        <InscripcionACursosTabla
          datos={materiasDisponibles}
          accion={postMateriasIncripcion}
          btnIcon={<BsArrowReturnRight />}
          btnVariant={"btn btn-success btn-sm"}
          btnTitle={"Inscribirse"}
          ocultar={false}
        />
      </Card>

      <Card className="shadow m-5 p-3">
        <h2 className="text-center m-3 ">Materias inscripto</h2>

        <div style={{ textAlign: "right" }}>
          {
            <Button
              className="bt btn-info mb-2"
              style={{ textAlign: "left", fontWeight: "bold" }}
              onClick={() => pdf()}
              disabled={materiasInscripto?.length === 0 || true}
            >
              {<BsFillFileEarmarkPdfFill />} Comprobante inscripción
            </Button>
          }
        </div>

        <InscripcionACursosTabla
          datos={materiasInscripto}
          accion={deleteMateriasIncripcion}
          btnIcon={<BsFillTrashFill />}
          btnVariant={"btn btn-danger btn-sm"}
          btnTitle={"Borrarse"}
          ocultar={true}
        />
      </Card>
    </>
  );
}
