import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Modal } from "react-bootstrap";
import Form from "./TelefonoFormModal";

export default function Telefono({ titulo, telefono, onSubmitCallback }) {
  const [show, setShow] = useState(false);

  return (

    <div className="float-left w-50 p-3">
      <Card className="border-primary shadow">
        <div className="card-header">
          <div className="panel-heading">
            <h5 className="panel-title">{titulo}</h5>
          </div>
        </div>
        <div className="panel-body">
          <p className="card-text text-left m-3">
            Teléfono: <b>{telefono?.telefono}</b>
          </p>
        </div>
        <div className="card-footer text-muted">
          <button className="btn btn-outline-primary mr-2 btn-sm"
            onClick={() => { setShow(true); }} >
            Modificar
          </button>
        </div>
      </Card>

      {
        <Modal
          size="lg"
          centered
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Actualizar telefóno <b>{titulo} </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmitCallback={onSubmitCallback}
              titulo={titulo}
              initialForm={telefono}
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>
      }

    </div>

  );
}
