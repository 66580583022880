import store from "./store";
import { Provider } from "react-redux";
import init from "./helpers/inicializacion";
import AppRouterStudent from "./Routers/AppRouterStudent";
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "bootstrap/dist/css/bootstrap.min.css"
import TimeOut from "./components/utils/TimeOut";
import {CLAVE_WEB} from "./helpers/constantes";
import {
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";


init();

function App() {
  

  return (
    <Provider store={store}>
      <TimeOut />
        <GoogleReCaptchaProvider
            language="es-AR"
            reCaptchaKey={CLAVE_WEB}
        >
      <AppRouterStudent  />
        </GoogleReCaptchaProvider>
    </Provider>
  );
}

export default App;
