import { Button, Card } from "react-bootstrap";
import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import {
  INSCRIPCION_FINAL_ENDPOINT,
  INSCRIPCION_FINAL_PDF_ENDPOINT,
} from "../../../helpers/endpoints";
import { useSelector, useDispatch } from "react-redux";
import { setMessageAction } from "../../../actions/messageActions";
import { helpHttp } from "../../../helpers/helpAxios";
import InscripcionFinalesTable from "./InscripcionFinalesTable";
import swal from "sweetalert2";
import { currencyFormatter, gridOptions } from "../../../helpers/tablaConfig";
import {
  BsArrowReturnRight,
  BsFillFileEarmarkPdfFill,
  BsFillTrashFill,
} from "react-icons/bs";
import { axiosDownloadFile } from "../../../helpers/axiosDownloadFile";

export default function InscripcionFinales() {
  const [mesasFienalesDisponibles, setMesasFienalesDisponibles] =
    useState(null);
  const [mesasFinalesInscripto, setMesasFinalesInscripto] = useState(null);
  const [todos, setTodos] = useState(null);
  const carreraSect = useSelector((state) => state.carr.carreraSect);
  const dispatch = useDispatch();

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const regIncripcion = (dato) => {
    return { ...dato, btnText: "Inscribir", disabled: false };
  };
  const regEliminar = (dato) => {
    return { ...dato, btnText: "Eliminar", disabled: false };
  };

  const getMateriasIncripcion = () => {
    helpHttp()
      .get(INSCRIPCION_FINAL_ENDPOINT + "/" + carreraSect.alumnoPrograma)
      .then((resp) => {
        setTodos(resp.data);
        let incripto = resp.data.filter((d) => d.estado !== "DISPONIBLE");
        setMesasFinalesInscripto(incripto.map((d) => regEliminar(d)));

        let disponible = resp.data.filter((d) => d.estado === "DISPONIBLE");
        setMesasFienalesDisponibles(disponible.map((d) => regIncripcion(d)));
      })
      .catch((error) => {
        setMesasFinalesInscripto([]);
        setMesasFienalesDisponibles([]);
        mensajeSistema(error.message, "ERROR");
      });
  };

  const info = (importe) => {
    if (importe != 0) {
      swal.fire({
        title: `Se incribio a un final postergado`,
        text: `Para finalizar la inscripcion, debe abonar ${importe}. 
                       De corresponder junto con el importe del final se debe abonar la matricula.`,
        icon: "info",
        buttons: true,
        dangerMode: true,
        toast: true,
      });
    }
  };

  const inscripcion = ({ mesaSelected }) => {
    const itemsToUpdate = [];
    const api = mesaSelected.api;
    let sel = [mesaSelected.data];
    itemsToUpdate.push(mesaSelected.data);
    console.log(mesaSelected);

    api.applyTransaction({ remove: [mesaSelected.data] });

    swal
      .fire({
        title: `Materia: ${mesaSelected.data.materia.descripcion}`,
        icon: "question",
        iconHtml: "؟",
        buttons: true,
        dangerMode: true,
        confirmButtonText: `Si`,
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          let seleccion = {
            ...mesaSelected.data,
            btnText: "Proce...",
            disabled: true,
          };

          mesaSelected.node.setData(seleccion);
         

          helpHttp()
            .post(
              INSCRIPCION_FINAL_ENDPOINT + "/" + carreraSect.alumnoPrograma,
              { data: mesaSelected.data }
            )
            .then((resp) => {
              setMesasFienalesDisponibles((mesasFienalesDisponibles) =>
                mesasFienalesDisponibles.filter((m) => m.id !== seleccion.id)
              );

              setMesasFinalesInscripto((prev) => [
                ...prev,
                regEliminar(resp.data),
              ]);

              const param = {
                value: resp.data.importe,
              };
              const importe = currencyFormatter(param);
              if (importe != 0) {
                info(importe);
              }
            })
            .catch((error) => {
              setMesasFienalesDisponibles((mesasFienalesDisponibles) =>
                mesasFienalesDisponibles.filter((m) => m.id !== seleccion.id)
              );
              setMesasFienalesDisponibles((prev) => [
                ...prev,
                regIncripcion(seleccion),
              ]);
              mensajeSistema(error.message, "ERROR");
            });
        }
      });
  };

  const deleteMateriasIncripcion = ({ mesaSelected }) => {
    let seleccion = {
      ...mesaSelected.data,
      btnText: "Proce...",
      disabled: true,
    };

    swal
      .fire({
        title: `Materia: ${mesaSelected.data.materia.descripcion}`,
        icon: "question",
        iconHtml: "؟",
        buttons: true,
        dangerMode: true,
        confirmButtonText: `Si`,
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setMesasFinalesInscripto((prev) =>
            mesasFinalesInscripto.map((item) => {
              if (item.id === seleccion.id) return seleccion;
              else return item;
            })
          );

          helpHttp()
            .put(
              INSCRIPCION_FINAL_ENDPOINT + "/" + carreraSect.alumnoPrograma,
              { data: mesaSelected.data }
            )
            .then((resp) => {
              setMesasFinalesInscripto((mesasFinalesInscripto) =>
                mesasFinalesInscripto.filter(
                  (m) => m.id !== mesaSelected.data.id
                )
              );
              setMesasFienalesDisponibles((prev) => [
                ...prev,
                regIncripcion(resp.data),
              ]);
            })
            .catch((error) => {
              setMesasFinalesInscripto((mesasFinalesInscripto) =>
                mesasFinalesInscripto.filter(
                  (m) => m.id !== mesaSelected.data.id
                )
              );
              setMesasFinalesInscripto((prev) => [
                ...prev,
                regEliminar(seleccion),
              ]);
              mensajeSistema(error.message, "ERROR");
            });
        }
      });
  };

  useEffect(() => {
    setMesasFienalesDisponibles(null);
    setMesasFinalesInscripto(null);
    carreraSect && getMateriasIncripcion();
  }, [carreraSect]);

  const pdf = () => {
    axiosDownloadFile(
      INSCRIPCION_FINAL_PDF_ENDPOINT + "/" + carreraSect?.alumnoPrograma,
      "cursosInscriptos"
    );
  };

  return (
    <>
      <Card className="shadow m-5 p-3">
        <h2 className="text-center mt-3 mb-5">Mesas de finales disponibles</h2>
        <div style={containerStyle}>
          <div
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
          >
            <div style={{ overflow: "hidden", flexGrow: "1" }}>
              <div style={gridStyle} className="ag-theme-alpine">
                <InscripcionFinalesTable
                  data={mesasFienalesDisponibles}
                  accion={inscripcion}
                  btnIcon={<BsArrowReturnRight />}
                  btnVariant={"btn btn-success btn-sm"}
                  btnTitle={"Inscribirse a final"}
                  ocultar={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="shadow m-5 p-3">
        <h2 className="text-center m-3 ">Mesas de finales inscripto</h2>
        <div style={{ textAlign: "right" }}>
          {
            <Button
              className="bt btn-info mb-2"
              style={{ textAlign: "left", fontWeight: "bold" }}
              onClick={() => pdf()}
              disabled={mesasFinalesInscripto?.length === 0 || true}
            >
              {<BsFillFileEarmarkPdfFill />} Comprobante inscripción
            </Button>
          }
        </div>
        <InscripcionFinalesTable
          data={mesasFinalesInscripto}
          accion={deleteMateriasIncripcion}
          btnIcon={<BsFillTrashFill />}
          btnVariant={"btn btn-danger btn-sm"}
          btnTitle={"Eliminar mesa de final"}
          ocultar={false}
        />
      </Card>
    </>
  );
}
