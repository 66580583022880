import { SET_ERRORES, SET_SUCCESS, SET_INFO } from "./types";

export const setMessageAction = ({ mensaje, type }) => {
  switch (type) {
    case "ERROR":
      return {
        type: SET_ERRORES,
        payload: { mensaje, type },
      };
    case "success":
      return {
        type: SET_SUCCESS,
        payload: { mensaje, type },
      };
    case "info":
      return {
        type: SET_INFO,
        payload: { mensaje, type },
      };
    default:
      break;
  }
};
