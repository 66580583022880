import NotasTabla from "./NotasTabla";
import { Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import swal from "sweetalert2";
import { NOTAS_AVG_ENDPOINT, NOTAS_ENDPOINT } from "../../../helpers/endpoints";
import { useSelector } from "react-redux";
import { helpHttp } from "../../../helpers/helpAxios";
import { formateNumber } from "../../../helpers/helpers";

const infoCalculoPromedio = (promedio) => {
  swal.fire({
    text: "El promedio se calcula asi",
    icon: "info",
    showCloseButton: true,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar',
  });
};

export default function NotasPages() {
  const [error, setError] = useState({});
  const [notas, setNotas] = useState(null);
  const [promedio, setPromedio] = useState(formateNumber.format(0));
  const carreraSect = useSelector((state) => state.carr.carreraSect);

  const getNotas = () => {
    helpHttp()
      .get(NOTAS_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => {
        setNotas(resp.data);
      })
      .catch((error) => {
        setError({
          resp: error?.response
            ? error.response.data.message
            : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
        });

        setNotas([]);
      });
  };
  const getAvg = () => {
    helpHttp()
      .get(NOTAS_AVG_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => {
        setPromedio(formateNumber.format(resp.data));
      })
      .catch((error) => {
        setError({
          resp: error?.response
            ? error.response.data.message
            : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
        });
        setPromedio(formateNumber.format(0));
      });
  };
  useEffect(() => {
    setNotas(null);
    carreraSect && getAvg();
    carreraSect && getNotas();
  }, [carreraSect]);

  return (
    <div>
      <Card className="shadow m-5 p-3">
        <NotasTabla
          promedio={promedio}
          notas={notas}
          infoCalculoPromedio={infoCalculoPromedio}
        />
      </Card>
    </div>
  );
}
