import Tabla from "./FehcaExamenesTabla";
import {Card} from "react-bootstrap";
import { useState, useEffect} from "react";
import axios from "axios";
import { INSCRIPCION_FECHA_EXAMEN_ENDPOINT } from "../../../helpers/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setMessageAction } from "../../../actions/messageActions";

export default function ConsultaFechaExamenPage() {

  const [fechaFinal, setFechaFinal] = useState(null);
  const carreraSect = useSelector((state) => state.carr.carreraSect);
  const dispatch = useDispatch();

  const mensajeSistema = (message, tipo) => {
    dispatch(setMessageAction({
        type: tipo,
        mensaje: message ? message : "Es este momento no podemos procesar la solicitud. Intente mas tarde"
    }));
};

  const getFechaFinal = () => {
    axios
      .get(INSCRIPCION_FECHA_EXAMEN_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => {
        setFechaFinal(resp.data);
      })
      .catch((error) => {
        const msj = error?.response ? error.response.data.message : "Es este momento no podemos procesar la solicitud. Intente mas tarde";
        mensajeSistema(msj, "ERROR");
      });
  };

  useEffect(() => {

    carreraSect && getFechaFinal();
  }, [carreraSect]);

  return (
    <div>
      <Card className="shadow m-5 p-3">
        {<Tabla data={fechaFinal} />}
      </Card>
    </div>
  );
}
