import { useState, useEffect } from "react";
import { helpHttp } from "../../helpers/helpAxios";
import { useDispatch } from "react-redux";
import { DEUDA } from "../../helpers/endpoints";
import { setMessageAction } from "../../actions/messageActions";
import PagoCuota from "./PagoCuota";
import PagoDeuda from "./PagoDeuda";
import swal from "sweetalert2";
import { formateCurrency } from "../../helpers/helpers";

export default function AlumnoDeuda() {
    const dispatch = useDispatch();
    const [datosDeuda, setDatosDeuda] = useState(null)

    const mensajeSistema = (message, tipo) => {
        dispatch(
            setMessageAction({
                type: tipo,
                mensaje: message
                    ? message
                    : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
            })
        );
    };

    const getDeuda = async () => {
        helpHttp().get(DEUDA).then(resp => {
            setDatosDeuda(resp.data)
        }).catch(error => {
            setDatosDeuda({});
            mensajeSistema(error.message, "ERROR");
        })
    };

    const realizarPago = (monto) => {
        let _monto = formateCurrency.format(monto);
        swal.fire({
            // title: `¿Quiere realizar el pago de ${_monto}?`,
            text: "Una ves reaizado el pago puede tardar hasta 72hs habíles en ver realizada la acreditación en su cuante corriente ",
            icon: "question",
            iconHtml: '؟',
            buttons: true,
            dangerMode: true,
            confirmButtonText: `Ralizar Pago de ${_monto}`,
            cancelButtonText: 'Mejor mas tarde',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            showCloseButton: true,

        }).then((result) => {
            if (result.isConfirmed) {
                helpHttp().post(DEUDA)
                    .then((resp) => window.open(resp.data))
                    .catch(error => {
                        console.log(JSON.stringify(error, 0, 2))
                        mensajeSistema("No se puede ralizar el pago en este momento. Intente mas terde.", "ERROR")
                    })
            }

        });
    }

    const pagosPendientes = () => {
        switch (datosDeuda.tipoPago) {
            case "cuota":
                return <PagoCuota data={datosDeuda} realizarPago={realizarPago} />

            case "deuda":
                return <PagoDeuda data={datosDeuda} realizarPago={realizarPago} />

            default:
                return <PagoCuota data={[]} realizarPago={realizarPago} />
        }

    }
    useEffect(() => {
        getDeuda()

    }, [])

    return (
        <div className="container mt-5">

            <div>{datosDeuda && pagosPendientes()}</div>

        </div>
    );
}