import { GridApi } from "ag-grid-community";
import { toast } from "react-toastify";

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const notifySuccess = (mensaje) =>
  toast.success(mensaje, {
    position: "top-right",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
  });

export const notifyError = (mensaje) =>
  toast.error(mensaje, {
    position: "top-right",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
  });

export const notifyInfo = (mensaje) =>
  toast.info(mensaje, {
    position: "top-right",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
  });

export const notifyWarning = (mensaje) =>
  toast.warning(mensaje, {
    position: "top-right",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
  });

export const exReg = {
  exNumeroTelefono:
    /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/,
  exEmail: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
};

export const formateCurrency = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "ARS",
  minimumFractionDigits: 2,
});

export const formateNumber = new Intl.NumberFormat("es-AR", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const stingToDate = (fecha) => {
  const [day, month, year] = fecha.split("/");
  return new Date(year, month - 1, day);
};
