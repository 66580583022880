import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { exReg } from "../../helpers/helpers";
import validator from 'validator';


const validate = valores => {
    let errores = {};

    if (!validator.isEmpty(valores.telefono)) {
        if (!exReg.exNumeroTelefono.test(valores.telefono)) {
            errores.telefono = 'El numero de teléfono no es validao.'
        }
    }
    return errores;
}

export default function TelefonoForm({ onSubmitCallback, initialForm, setShow }) {
    const formik = useFormik({
        initialValues: initialForm,
        validate,
        onSubmit: values => {
            onSubmitCallback(values);
            setShow(false);
        },
    });

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit} autoComplete="off" >
                <Row>
                    <Col>

                        <Form.Group control="telefono">
                            <Form.Label htmlFor="telefono">Teléfono</Form.Label>
                            <Form.Control
                                type="tel"
                                name="telefono"
                                id="telefono"
                                placeholder="Teléfono"
                                value={formik.values.telefono}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.telefono && formik.errors.telefono}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.telefono && <div><p> {formik.errors.telefono}
                                    <br />
                                    Ingrear coddigo de area sin 0 y numero de teléfono sin 15</p>
                                </div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button
                            variant="primary btn-block"
                            type="submit"
                            disabled={!formik.dirty}
                        >
                            Guardar
                        </Button>
                    </Col>
                </Row>

            </Form>

        </Container >

    )
}
