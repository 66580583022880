// 👇️ using a CDN to import axios

import axios from "axios";

export const axiosDownloadFile = (url, fileName) => {
  return axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
    },
  })
    .then((response) => {
      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = fileName.replace(".", "");

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.error("error: ", error);
    });
};
