import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  onFirstDataRendered,
  AG_GRID_LANGUAGE_ES,
  exportSeparator,
  dateFormateYYYYMMDD,
  timeStamp,
} from "../../../helpers/tablaConfig";
import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";

const incialData = [
  {
    id: 0,
    materia: { descred: "", descripcion: "" },
    horariosMateria: "",
    fechaMesa: "",
    horaExamen: "",
    profesores: "",
  },
];

export default function ConsultaNotasTabla({
  datos,
  accion,
  btnIcon,
  btnVariant,
  btnTitle,
  ocultar,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tiempoImpresion, setTiempoImpresion] = useState(null);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      wrapText: true,
      sortable: true,
      resizable: true, // permite cambiar el acho de la columna
      unSortIcon: true,
      filter: true,
      floatingFilter: false,
      animateRows: true,
    };
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtExport = () => {
    exportSeparator(gridApi);
  };

  useEffect(() => {
    setTiempoImpresion(timeStamp());
  }, []);

  /*intento de agregar un boton */

  const inscripcion = (params) => {
    return (
      <Button
        style={{ height: 25, lineHeight: 0.5 }}
        onClick={() => accion({ cursoSelected: params })}
        variant={btnVariant}
        data-toggle="tooltip"
        data-placement="top"
        title="Eliminar"
        disabled={params.data.disabled}
      >
        {btnIcon} {params.data.btnText}
      </Button>
    );
  };

  const columnDef = [
    {
      headerName: "",
      field: "id",
      minWidth: 80,
      sortable: false,
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      cellRendererFramework: (params) => inscripcion(params),
    },
    {
      headerName: "Curso",
      field: "descripcionCurso",
      minWidth: 350,
      maxWidth: 700,
      cellStyle: { textAlign: "left", "white-space": "normal" },
      autoHeight: true,
    },
    {
      headerName: "Tipo de Clase - Horario",
      field: "horariosMateria",
      minWidth: 150,
      maxWidth: 500,
    },
    {
      headerName: "Año",
      field: "anioCarrera",
      minWidth: 50,
      maxWidth: 200,
    },
    {
      headerName: "Profesor(es)",
      field: "docentesCurso",
      minWidth: 400,
      maxWidth: 700,
      cellStyle: { textAlign: "left", "white-space": "normal" },
      autoHeight: true,
    },

    {
      headerName: "Cupo",
      field: "cupo",
      minWidth: 0,
      maxWidth: 100,
      sortable: false,
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      hide: ocultar,
    },
    {
      headerName: "",
      field: "",
      minWidth: 0,
      maxWidth: 100,
      sortable: false,
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      hide: !ocultar,
    },
  ];
  return (
    <>
      <div id="notasGrid" className="ag-theme-material" style={{ height: 400 }}>
        <AgGridReact
          rowData={datos}
          pagination={true}
          defaultColDef={defaultColDef}
          paginationPageSize={10}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          localeText={AG_GRID_LANGUAGE_ES}
          columnDefs={columnDef}
          resizable={true}
          animateRows={true}
        />
      </div>
    </>
  );
}
