import { SET_CARRERAS_ALUMNO } from "../actions/types";


const initialState = {};

export default function (state = initialState, {type, payload}){

        switch(type){
        case SET_CARRERAS_ALUMNO:    
        return{
                  ...state,
                carreraSect: payload,
        }
        default:
            return state;
    }
}