import { AgGridReact } from 'ag-grid-react';
import {
    gridOptions,
    onFirstDataRendered,
    AG_GRID_LANGUAGE_ES,
    filterParams,
    currencyFormatter,
    dateComparator,
    exportSeparator
} from "../../../helpers/tablaConfig"
import {useState} from "react";
import {Button} from "react-bootstrap";
import {GrDocumentCsv} from "react-icons/gr";
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css';


export default function InscripcionFinalesTable({data, inscripcion}) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };


    const onBtExport = () => {
        gridApi.exportDataAsCsv();
    };



    const columnDef = [


        {
            headerName: "Mesa",
            field: "id",
            minWidth: 150,
            maxWidth: 200,
            cellStyle: {textAlign: 'right'}
        },
        {
            headerName: "Materia",
            field: "materia.materiaDesc",
            minWidth: 300,
            cellStyle: {textAlign: 'left','white-space': 'normal'},
            autoHeight: true
        },
        {
            headerName: "Fecha",
            field: "fechaMesa",
            minWidth: 150,
            maxWidth: 500,
            cellStyle: {textAlign: 'center'},
            filter: "agDateColumnFilter",
            filterParams: filterParams,
            comparator: dateComparator
        }

    ];
    return (
        <>
            <div style={{textAlign: 'right'}}>
                <Button
                    onClick={() => onBtExport()}
                    disabled={!(data && data.length > 0)}
                    style={{fontWeight: "bold"}}
                    className="bt btn-info mb-2"
                ><GrDocumentCsv/> Exportar CSV</Button>
            </div>
            <div id="notasGrid" className="ag-theme-material" style={{height: 600}}>
              <AgGridReact rowData={data}
                           pagination={true}
                           gridOptions={gridOptions}
                           onGridReady={onGridReady}
                           paginationPageSize={10}
                           localeText={AG_GRID_LANGUAGE_ES}
                           onFirstDataRendered={onFirstDataRendered}
                           columnDefs={columnDef}
                           resizable={true}
                           animateRows={true} >
                </AgGridReact>

            </div>
        </>
    );
}
