import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CURSO_INSCRIPCIONES_ENDPOINT } from "../../../helpers/endpoints";
import {Card} from "react-bootstrap";
import IncripcionesMateriasTabla from "./IncripcionesMateriasTabla";
import { setMessageAction } from "../../../actions/messageActions";


export default function ConsultaInscrionMateria() {

    const [materias, setMaterias] = useState(null);
    const carreraSect = useSelector((state) => state.carr.carreraSect);
    const dispatch = useDispatch();

    const mensajeSistema = (message, tipo) => {
      dispatch(setMessageAction({
          type: tipo,
          mensaje: message ? message : "Es este momento no podemos procesar la solicitud. Intente mas tarde"
      }));
  };

    const getNotas = () => {
      carreraSect && (axios
          .get(CURSO_INSCRIPCIONES_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
          .then((resp) => {
            setMaterias(resp.data);
          }))
          .catch((error) => {
           
            const msj = error?.response ? error.response.data.message : "Es este momento no podemos procesar la solicitud. Intente mas tarde";
            mensajeSistema(msj, "ERROR");
        
          });
      };

      useEffect(() => {
        setMaterias(null);
        carreraSect && getNotas();
      }, [carreraSect]);

    return (

        <Card className="shadow m-5 p-3">

           <IncripcionesMateriasTabla materias={materias} />
        
        </Card>
    )
}
