import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  gridOptions,
  onFirstDataRendered,
  AG_GRID_LANGUAGE_ES,
  exportSeparator,
  dateComparator,
} from "../../../helpers/tablaConfig";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useState, useRef, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { axiosDownloadFile } from "../../../helpers/axiosDownloadFile";
import { CERTIFICADO } from "../../../helpers/endpoints";
import { BsFillCloudArrowDownFill } from "react-icons/bs";
import { formateNumber, stingToDate } from "../../../helpers/helpers";
import { de } from "date-fns/locale";

export default function NotasTabla({ promedio, notas, infoCalculoPromedio }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
    window.addEventListener("resize", function () {
      setTimeout(function () {
        newPageSize.api.sizeColumnsToFit();
      });
    });
    gridRef.current.api.sizeColumnsToFit();
  };

  const nota = (params) => {
    if (params.value === "number") {
      return formateNumber.format(params.value.toFixed(2)).toString();
    }

    switch (params.data.notaLetra) {
      case "E":
      case "U":
      case "AUS":
        return "";
      default:
        return params.data.notaLetra;
    }
  };
  const isDownloadSertificado = (params) => {
    const fechaExamenDate = stingToDate(params.data.fechaExamen);
    const currentDate = new Date();
    const fourMonthsAgo = new Date(
      currentDate.setMonth(currentDate.getMonth() - 4)
    );

    if (fechaExamenDate >= fourMonthsAgo) {
      // solo los certificados que el examen paso menos de un mes

      if (params.value === "number") {
        return true && !params.data.promosion;
      }
      switch (params.data.notaLetra) {
        case "E":
        case "U":
        case "AUS":
          return false;
        default:
          return true && !params.data.promosion;
      }
    }
    return false;
  };
  const onBtExport = () => {
    exportSeparator(gridApi);
  };

  const columnDef = [
    {
      headerName: "Código Mataria",
      field: "descred",
    },
    {
      headerName: "Materia",
      field: "descripcion",
      resizable: true,
      floatingFilter: false,
      filter: true,
    },
    {
      field: "fechaExamen",
      maxWidth: 200,
      comparator: dateComparator,
    },
    {
      field: "tipoEvaluacion",

      unSortIcon: true,
      filter: true,
      floatingFilter: false,
    },
    {
      headerName: "Nota",
      field: "notaNumero",
      valueFormatter: (params) => nota(params),
    },
    {
      headerName: "Aprobado",
      field: "aprobado",
    },
    {
      headerName: "Certificado examen",
      field: "accion",
      sortable: false,
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: (params) => btnCertificado(params),
    },
  ];
  const btnCertificado = (params) => {
    return (
      <Button
        style={{ height: 25, lineHeight: 0.5 }}
        onClick={() => accion({ params })}
        data-placement="top"
        disabled={!isDownloadSertificado(params)} //permito bajar el sertificado si la nota el alfa mail de luis 14/06/2024
        title="El certificado está disponible para descargar durante 4 meses."
      >
        {<BsFillCloudArrowDownFill />}
      </Button>
    );
  };

  const accion = ({ params }) => {
    axiosDownloadFile(
      CERTIFICADO + "/" + params.data.id,
      params.data.tipoEvaluacion +
        "_" +
        params.data.descripcion +
        "_" +
        params.data.fechaExamen
    );
  };

  return (
    <>
      <Row className="m-2 ">
        <Col style={{ textAlign: "left" }}>
          <Button
            disabled={!notas}
            style={{ fontWeight: "bold" }}
            className="bt btn-info mb-2"
          >
            Promedio: {promedio}
          </Button>
        </Col>

        <Col style={{ textAlign: "right" }}>
          <Button
            onClick={() => onBtExport()}
            disabled={!notas}
            style={{ fontWeight: "bold" }}
            className="bt btn-info mb-2"
          >
            Exportar CSV
          </Button>
        </Col>
      </Row>

      <Row
        id="notasGrid"
        className="ag-theme-material m-2 mt-4"
        style={{ height: 600 }}
      >
        <Col>
          <div style={containerStyle}>
            <div
              style={{ display: "flex", flexDirection: "row", height: "100%" }}
            >
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                  rowData={notas}
                  pagination={true}
                  gridOptions={gridOptions}
                  paginationPageSize={10}
                  onGridReady={onGridReady}
                  localeText={AG_GRID_LANGUAGE_ES}
                  onFirstDataRendered={onFirstDataRendered}
                  columnDefs={columnDef}
                  animateRows={true}
                  resizable={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
