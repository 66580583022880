import { useState, useEffect } from "react";
import PersonaDatos from "./PersonaDatos";
import LugarNacimiento from "./LugarNacimiento";
import TelefonoList from "./TelefonoList";
import { Container, Spinner } from "react-bootstrap";
import CorreoList from "./CorreoList";
import { LOCALIDAD, PAIS, PERSONA_ENDPOINT, PROVINCIA } from "../../helpers/endpoints";
import { helpHttp } from "../../helpers/helpAxios";
import { setMessageAction } from "../../actions/messageActions";
import { useDispatch } from "react-redux";
import DireccionParticular from "./DireccionParticular";


export default function DatosPersonalesPage() {
  const [datosPersona, setDatosPersona] = useState({});
  const [datosLogarNacimiento, setDatosLogarNacimiento] = useState({});
  const [datosDireccion, setDatosDireccion] = useState({});
  const [datosTelefonos, setDatosTelefonos] = useState(null);
  const [datosEmails, setDatosEmails] = useState(null);
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const dispatch = useDispatch();


  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };


  const getDatosPersona = async () => {
    helpHttp().get(PERSONA_ENDPOINT + "/persona").then(resp => {
      setDatosPersona(resp.data)
    }).catch(error => mensajeSistema(error.message, "ERROR"))
  };

  const getLugarNacimiento = async () => {
    await helpHttp().get(PERSONA_ENDPOINT + "/lugarNacimiento")
      .then(resp => setDatosLogarNacimiento(resp.data))
      .catch(error => mensajeSistema(error.message, "ERROR"));
  };

  const getDireccion = async () => {
    await helpHttp()
      .get(PERSONA_ENDPOINT + "/direccionParticular")
      .then(resp => { setDatosDireccion(resp.data); })
      .catch(error => mensajeSistema(error.message, "ERROR"));
  };

  const onSubmitCallbackDireccion = (values) => {
    helpHttp()
      .put(PERSONA_ENDPOINT + "/direccionParticular", {
        data: values,
      })
      .then((resp) => {
        setDatosDireccion(resp?.data)
        mensajeSistema("Actualizado con exito!!!", "success");
      })
      .catch((error) => { mensajeSistema(error.message, "ERROR") });
  }

  const getTelefonos = async () => {
    await helpHttp()
      .get(PERSONA_ENDPOINT + "/telefonos")
      .then(resp => {
        setDatosTelefonos(resp.data)
      })
      .catch(error => mensajeSistema(error.message, "ERROR"));
  };

  const onSubmitCallbackTelefono = (values) => {
    helpHttp().put(PERSONA_ENDPOINT + "/telefono", {
      data: values,
    })
      .then((resp) => {
        getTelefonos()
        mensajeSistema(
          "Actualizado con exito!!!",
          "success"
        );
      })
      .catch((error) => { mensajeSistema(error.message, "ERROR") });
  }
  const getEmails = async () => {
    await helpHttp().get(PERSONA_ENDPOINT + "/emails")
      .then(resp => {
        setDatosEmails(resp.data);
      })
      .catch(error => mensajeSistema(error.message, "ERROR"));
  };


  const onSubmitCallbackEmail = async (values) => {
    await helpHttp().put(PERSONA_ENDPOINT + "/emails", {
      data: values,
    })
      .then((resp) => {
        getEmails();
        mensajeSistema(
          "Actualizado con exito!!!",
          "success"
        );
      })
      .catch((error) => { mensajeSistema(error.message, "ERROR") });

  }

  const getListaPaises = () => {
    helpHttp()
      .get(PAIS)
      .then(resp => {
        setPaises(resp?.data);
        getListaProvinciasPorPais(datosDireccion.pais.id);
        getListaLocalidadesPorPaisYProvincia(datosDireccion.pais.id, datosDireccion.provincia.id);
      }
      )
      .catch(error => mensajeSistema(error.message, "ERROR"));
  };

  const getListaProvinciasPorPais = async (paisId) => {
    await helpHttp()
      .get(PROVINCIA + "/" + paisId)
      .then(resp => {
        setProvincias(resp?.data);
      }
      ).catch(error => mensajeSistema(error.message, "ERROR"));
  }

  const getListaLocalidadesPorPaisYProvincia = async (paisId, provinciaId) => {
    let url = paisId ? (LOCALIDAD + "/" + provinciaId + "/" + paisId) : LOCALIDAD + "/" + provinciaId;
    await helpHttp()
      .get(url)
      .then(resp => {
        setLocalidades(resp?.data);
      }
      )
      .catch(error => mensajeSistema(error.message, "ERROR"));
  }



  const changeSelect = (e) => {


    const { name, value } = e.target;

    switch (name) {
      case "pais.id":
        getListaProvinciasPorPais(value);
        setLocalidades([{
          id: -1,
          nombre: "Seleccione una provincia"
        }])
        break;
      case "provincia.id":
        getListaLocalidadesPorPaisYProvincia(null, value);
        break;
      default:
        break;
    }

  }

  useEffect(() => {
    getDatosPersona()
    getLugarNacimiento();
    getDireccion();
    getTelefonos();
    getEmails();
  }, [])

  return (

    <Container className="mt-3 mb-5 text-center ">

      <PersonaDatos
        persona={datosPersona}
        registro={datosPersona.registro} />
      <LugarNacimiento lugarNacimiento={datosLogarNacimiento} />
      <DireccionParticular
        direccion={datosDireccion}
        onSubmitCallbackDireccion={onSubmitCallbackDireccion}
        getListaPaises={getListaPaises}
        paises={paises}
        provincias={provincias}
        localidades={localidades}
        changeSelect={changeSelect} />
      <TelefonoList
        telefonos={datosTelefonos}
        onSubmitCallback={onSubmitCallbackTelefono}
      />
      <CorreoList emails={datosEmails}
        onSubmitCallback={onSubmitCallbackEmail}
      />

    </Container>


  );
}
