import axios from "axios";

export const  helpHttp = () => {

    const  customFetch = async (endpoint, options) => {
 
     return await axios(endpoint,options);
        let rest;

        switch (options.method) {
          case "POST":
        
            rest = await axios.post(endpoint, options.data ,{
              headers: {'Accept': 'application/json', 'Content-type': 'application/json','Accept-Encoding':'gzip', 'x-accept-encoding':'gzip'}
          });
            break;

          default:
      
            break;
        
        }
        return await rest.data;
    };
  
    const get = async (url, options = {}) => customFetch(url, options);
  
    const post = (url, options = {}) => {
   
      options= {...options,
                method: "POST",
              };
               
      return customFetch(url, options);
    };
  
    const put = (url, options = {}) => {
      options=  {method: "PUT",};
      return customFetch(url, options);
    };
  
    const del = (url, options = {}) => {
      options=  {method: "DELETE",};
      return customFetch(url, options);
    };
  
    return {
    
      get,
      post,
      put,
      del,
    };
  };