export const config = {
  axiosSegundos: 60,
  timeOutMinutos: 15,
};

export const cursoEstado = {
  DISPONIBLE: "DISPONIBLE",
  INSCRIPTO: "INSCRIPTO",
  LISTA_ESPERA: "LISTA_ESPERA",
};

export const RECAPTCHA_KEY = "6LdXhzgeAAAAAIICE3jzXLXp1ORqogpE1qy0c2O3";
export const CLAVE_WEB = "6Ldl_z4eAAAAAEkPDevbDzTePF0W1jfi1TBbslwU";
