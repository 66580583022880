import React from "react";
import { Route, Redirect , useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {hasAnyAuthority} from "../../helpers/roles";
import routers from "../../helpers/routers";

export default function PrivateRoute({
  hasRole,
  component: Component,
  hasAnyAuthorities = [],
  ...rest
}) {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const authorities = useSelector((state) => state.auth.authorities);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === true ? (
          hasAnyAuthority(hasRole, authorities) ? (
            <Component {...props} />
          ) : (
            <Redirect to={routers.accesoDenegado} />
          )
        ) : (
          <Redirect to={{pathname: routers.root, state: {from: location}}} />
        )
      }
    ></Route>
  );
}
