import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Navigation from "./Navigation";


export default function LayoutStudent({ children }) {

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <Navigation />
      {children}
    </>
  );
}
