import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import "moment/locale/es";
import { logoutUser, setCurrentUser } from "../actions/authActions";
import store from "../store";
import { config } from "./constantes";
import routers from "./routers";
import setAuthToken from "./setAuthToken";

const init = () => {
  checkForToken();
  axiosConfig();
  moment.locale("es");
};

export const checkForToken = () => {
  if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);

    const decoded = jwt_decode(localStorage.jwtToken);
    const user = {
      lastName: decoded.apellido,
      firstName: decoded.nombre,
      authorities: decoded.authorities,
      sub: decoded.user_name,
      authorities: decoded.authorities,
    };

    store.dispatch(
      setCurrentUser({
        user,
        loggedIn: true,
      })
    );

    const currentTime = Math.floor(Date.now() / 1000);

    if (decoded.exp < currentTime) {
      store.dispatch(logoutUser());
      window.location.href = routers.principal;
    }
  }
};

export const axiosConfig = () => {
  axios.defaults.timeout = 1000 * 60 * config.axiosSegundos;
  axios.defaults.timeoutErrorMessage = "Tiepo de espera agotado";
  axios.defaults.headers["Accept"] = "application/json";
};

export default init;
