import { AgGridReact } from "ag-grid-react";
import { gridOptions, onFirstDataRendered, AG_GRID_LANGUAGE_ES } from '../../helpers/tablaConfig';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useState } from "react";


export default function TamiteMateriaTabla({data, onRowSelected,fielaSecleccionada, edit}) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
     
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
      };


       const columnDef = [
         {
          headerName: "Materia",
          field: "descripcion",
          cellStyle: { textAlign: 'left' }
        },
       
      ];

    return (
        <>
        <h6>Total materias seleccionadas: {edit.current ? fielaSecleccionada.length :  data?.length }</h6>
        <div id="materiasGrid" className="ag-theme-material" style={{ height: 500 }}>
     
        <AgGridReact
          functionsReadOnly={true}
          rowData={data}
          columnDefs={columnDef}
          pagination={true}
          paginationPageSize={10}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          localeText={AG_GRID_LANGUAGE_ES}
          onFirstDataRendered={onFirstDataRendered}
          rowSelection={edit.current?'multiple':''}
          rowMultiSelectWithClick={edit.current}
          floatingFilter= {false}
          onRowSelected={edit.current?onRowSelected:null}
        />
      </div>
       </>     

    )
}
