import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import roles from "../helpers/roles";
import routers from "../helpers/routers";
import LayoutStudent from "../layouts/LayoutStudent";
import AlumnoDeuda from "../components/alumnoDeuda/AlumnoDeuda";
import ConsultaInscrionMateria from "../components/Consultas/InscripcionesMaterias/ConsultaInscripcionMaterias";
import DatosPersonalesPage from "../components/datosPersonales/DatosPersonalesPage";
import InscripcionFinales from "../components/inscripciones/InscripcionFinales/InscripcionFinales";
import InscripcionCursos from "../components/inscripciones/inscripcionCursos/InscripcionCursos";
import ConsultaFechaExamenPage from "../components/Consultas/ConsultaFechaExamen/ConsultaFechaExamenPage";
import NotasPages from "../components/Consultas/Notas/NotasPages";
import Home from "../components/Home";
import ConsultaCatalogo from "../components/biblioteca/ConsultaCatalogo";
import Historico from "../components/biblioteca/Historico";
import Renovar from "../components/biblioteca/Renovar";
import ListaPagos from "../components/alumnoDeuda/ListaPagos";
import SignIn from "../components/signIn/SignIn";
import StartOfProcess from "../components/inicioTramite/InicioTramite";
import PrivateRoute from "../components/utils/PrivateRoute";
import ImagenHeader from "../components/Imagenes/ImagenHeader";
import { useSelector } from "react-redux";
import MensajesSistema from "../components/MensajesSistema";

import Footer from "../layouts/Footer";
import HistoricoInscricionFinales from "../components/Consultas/HisoticoInscripcionFinales/HistoricoInscricionFinales";
import BlanqueoClave from "../components/clave/BlanqueoClave";
import RecuperoClave from "../components/clave/RecuperoClave";
import CertificadoExamen from "../components/certificadoExamen/CertificadoExamen";
import AccesDanegate from "../components/AccesDanegate";
import NoFoundPage from "../components/NoFoundPage";

export default function AppRouterStudent() {
  const errorSitema = useSelector((state) => state.err);

  return (
    <Router>
      <LayoutStudent />
      <ImagenHeader />
      <MensajesSistema />

      <Switch>
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.principal}
          component={Home}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.inscripcionesMaterias}
          component={InscripcionCursos}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.inscripcionesFinales}
          component={InscripcionFinales}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.consultasNotas}
          component={NotasPages}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.consultaMaterias}
          component={ConsultaInscrionMateria}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.finalesHistorico}
          component={HistoricoInscricionFinales}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.consultaFechasExamenes}
          component={ConsultaFechaExamenPage}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.tramite}
          component={StartOfProcess}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.pagos}
          component={AlumnoDeuda}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.miCuenta}
          component={DatosPersonalesPage}
        />

        <PrivateRoute
          hasRole={[roles.admin, roles.administrativo]}
          exact
          path={routers.administrativo.listadoPagos}
          component={ListaPagos}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.consultaCatalogo}
          component={ConsultaCatalogo}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.historico}
          component={Historico}
        />
        <PrivateRoute
          hasRole={[roles.alumno]}
          exact
          path={routers.renovar}
          component={Renovar}
        />

        <Route exact path={routers.blanqueoClave} component={BlanqueoClave} />
        <Route exact path={routers.recuperoClave} component={RecuperoClave} />
        <Route
          exact
          path={routers.certificadoExamen}
          component={CertificadoExamen}
        />
        <Route exact path="/" component={SignIn} />
        <Route exact path={routers.accesoDenegado} component={AccesDanegate} />
        <Route path={routers.e404} component={NoFoundPage} />
      </Switch>

      <Footer />
    </Router>
  );
}
