const roles = {
    alumno: "ROLE_ALUMNO",
    admin: "ROLE_ADMIN",
    administrativo: "ROLE_ADMINISTRATIVO"
}

export default roles;

export const hasAnyAuthority = (authorities, hasAnyAuthorities) => {
    if (authorities && authorities.length !== 0) {
      if (hasAnyAuthorities.length === 0) {
        return true;
      }
      return hasAnyAuthorities.some(auth => authorities.includes(auth));
    }
    return true;
  }

