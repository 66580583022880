import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { formateCurrency } from "../../helpers/helpers";


export default function PagoDeuda({ data, realizarPago }) {

    return (
        <Card className="border-primary shadow">
            <div className="panel panel-info">
                <div className="panel-heading">
                    <div className="card-header">
                        <h3 className="panel-title">Deuda</h3>
                    </div>
                </div>
                <div className="panel-body">

                    <Row className="mt-3 text-left pl-3">
                        <Col>  Importe Cuota: <b> {formateCurrency.format(data.deudaDto.importeCuota)}</b></Col>
                        <Col>  Importe Materia: <b> {formateCurrency.format(data.deudaDto.importeMateria)}</b></Col>
                    </Row>
                    <Row className="mt-3 text-left pl-3">
                        <Col>  Intereces a la fecha: <b> {formateCurrency.format(data.deudaDto.importeInteres)}</b></Col>
                        <Col>  Otros conceptos: <b> {formateCurrency.format(data.deudaDto.importeOtro)}</b></Col>
                    </Row>
                    <div className="card-footer text-muted mt-5">
                        <Button
                            variant="primary btn-block"
                            type="submit"
                            disabled={(data.deudaDto.importeTotal || 0) <= 0}
                            onClick={() => realizarPago(data.deudaDto.importeTotal)} >
                            Pagar deuda: {formateCurrency.format(data.deudaDto.importeTotal)}
                        </Button>
                    </div>

                </div>
            </div>
        </Card >
    );
}