import { useState } from "react";
import { Container, Row, Col, Card, Modal, Spinner } from "react-bootstrap";
import Form from "./DireccionParticularModal";



export default function DireccionParticular({ direccion, onSubmitCallbackDireccion, getListaPaises, paises, provincias, localidades, changeSelect }) {
  const [show, setShow] = useState(false);

  return (

    <>
      <Card className="border-primary shadow mt-2">
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="card-header">
              <h3 className="panel-title">Dirección particular</h3>
            </div>
          </div>
          <div className="panel-body">
            <Container>
              {(!direccion) ?

                (<Spinner animation="grow" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>)
                :

                <>
                  <Row className="mt-3 mb-3 text-left">
                    <Col>
                      Calle: <b>{direccion.direccion}</b>
                    </Col>
                    <Col>
                      Nro: <b>{direccion.altura}</b>
                    </Col>
                    <Col>
                      Piso: <b>{direccion.piso}</b>
                    </Col>

                    <Col>
                      Departamento: <b>{direccion.dto}</b>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-3 text-left">
                    <Col>
                      País: <b>{direccion.pais?.nombre}</b>
                    </Col>
                    <Col>
                      Provincia: <b>{direccion.provincia?.nombre}</b>
                    </Col>
                    <Col>
                      Localidad: <b>{direccion.localidad?.nombre}</b>
                    </Col>
                    <Col>
                      {""}
                    </Col>
                  </Row></>
              }

            </Container>
          </div>
          {direccion &&
            (<div className="card-footer text-muted">
              <button className="btn btn btn-success mr-2 btn-block"
                onClick={() => { getListaPaises(); setShow(true); }}
              >
                Actulizar Dirección
              </button>
            </div>)
          }

        </div>
      </Card>

      {
        <Modal
          size="lg"
          centered
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Actualizar Dirección
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmitCallback={onSubmitCallbackDireccion}
              initialForm={direccion}
              paises={paises}
              provincias={provincias}
              localidades={localidades}
              changeSelect={changeSelect}
              setShow={setShow}
            />
          </Modal.Body>
        </Modal>
      }

    </>
  )
}
