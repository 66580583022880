import { SET_CURRENT_USER } from "../actions/types";

const initialState = {loggedIn: false, user: {}, authorities: []};

export default function (state = initialState, {type, payload}){

    switch(type){
        case SET_CURRENT_USER:
            return{
                ...state,
                loggedIn: payload.loggedIn,
                user: payload.user,
                authorities: payload.user.authorities,
            }
        default:
            return state;
    }
}