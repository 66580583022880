import React, { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import TablaMaterias from "./TamiteMateriaTabla";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

export default function ModalTramiteForm({
  tipoTramite,
  onSubmitCallback,
  initialForm,
  errors,
  cargarMaterias,
  dataMateria,
  edit,
}) {
  const [form, setForm] = useState(initialForm);
  const [fielaSecleccionada, setFielaSecleccionada] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    edit.current && value === "PROGRAMA" && cargarMaterias();
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onRowSelected = async (params) => {
    if (params.node.isSelected()) {
      await setFielaSecleccionada([
        ...fielaSecleccionada,
        { materiaId: params.data.materiaId },
      ]);
    } else {
      await setFielaSecleccionada(
        fielaSecleccionada.filter((f) => f.materiaId != params.data.materiaId)
      );
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (form.tipoTramite === "PROGRAMA") {
      onSubmitCallback({ ...form, materias: fielaSecleccionada });
    } else {
      onSubmitCallback({ ...form, materias: [{}] });
    }
  };

  const handleInputChange = (e) => {
    form.isCertificadoMinisterio = !form.isCertificadoMinisterio;
  };

  return (
    <Form onSubmit={submitForm}>
      <Row>
        <Col>
          {edit.current ? (
            <Form.Group control="tipoTramite">
              <Form.Label>Tipo Tramite</Form.Label>
              <Form.Control
                as="select"
                name="tipoTramite"
                id="tipoTramite"
                value={form.tipoTramite}
                onChange={handleChange}
                disabled={!edit.current}
                isInvalid={errors.tipoTramite}
              >
                <option value="" disabled>
                  Elige un tipo de tramite
                </option>
                {tipoTramite ? (
                  tipoTramite.map((element) => (
                    <option key={element.valor} value={element.valor}>
                      {" "}
                      {element.meaning}{" "}
                    </option>
                  ))
                ) : (
                  <option key="vacio" value="vacio" disabled>
                    {" "}
                    Cargando Tramites...{" "}
                  </option>
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.tipoTramite && <p> {errors.tipoTramite} </p>}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group control="tipoTramite">
              <Form.Label>Tipo Tramite</Form.Label>
              <Form.Control
                as="select"
                name="tipoTramite"
                id="tipoTramite"
                disabled={true}
              >
                <option value={initialForm.tipoTramite}>
                  {initialForm.meaning}
                </option>
              </Form.Control>
            </Form.Group>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group control="observaciones">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              name="observaciones"
              value={form.observaciones ? form.observaciones : ""}
              onChange={(e) => handleChange(e)}
              placeholder="Observaciones"
              disabled={!edit.current}
              readOnly={!edit.current}
            />
          </Form.Group>
        </Col>
      </Row>

      {(form.tipoTramite === "CERT_PARCIAL") && (
        <>
          <Row>
            <Col>
              <Form.Group control="datosAdicionales">
                <Form.Label>Datos Adicionales</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="datosAdicionales"
                  value={form.datosAdicionales ? form.datosAdicionales : ""}
                  onChange={(e) => handleChange(e)}
                  placeholder="Datos Adicionales"
                  disabled={!edit.current}
                  readOnly={!edit.current}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Form.Group className="mb-3 ml-3" controlId="formBasicCheckbox">
          <Form.Check 
            name="isCertificadoMinisterio"
            type="switch"
            checked={form.isCertificadoMinisterio}
            onChange={() => handleInputChange()}
            defaultChecked={false}
            readOnly={!edit.current}
            label="Certificado por ministerio"
          />
          </Form.Group>
         
          </Row>
        </>
      )}

      {(form.tipoTramite === "PROGRAMA" && dataMateria) && (
        <>
          <Row>
            <Col>
              <br />
              <TablaMaterias
                data={dataMateria}
                onRowSelected={onRowSelected}
                fielaSecleccionada={fielaSecleccionada}
                edit={edit}
              />
            </Col>
          </Row>
        </>
      )}

      <Button
        variant="primary btn-block"
        type="submit"
        disabled={!edit.current}
      >
        Iniciar
      </Button>
    </Form>
  );
}
