import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Container, Spinner } from "react-bootstrap";
import Correo from "./Correo";

export default function CorreoList({ emails, onSubmitCallback }) {
  return (
    <div className="mt-2">
      <Card className="border-primary shadow">
        <div className="card-header">
          <h3 className="panel-title">Correos</h3>
        </div>
        <div className="panel-body ">

          <Container>

            {(!emails) ?

              (<Spinner animation="grow" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>)
              :
              <>
                {
                  emails.map((e,i) => (
                    e.tipoMail === "e-mail 1" ?
                      <Correo key={i} titulo={"Particular"} email={e} onSubmitCallback={onSubmitCallback} isEditable={true} /> :
                      <Correo key={i} titulo={"Institucional"} email={e} onSubmitCallback={onSubmitCallback} isEditable={false} />
                  ))
                }
              </>
            }
          </Container>

        </div>

      </Card>
    </div>

  )
}
