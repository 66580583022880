import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import validator from 'validator';


const validate = valores => {
    let errores = {};

    if (validator.isEmpty(valores.email)) {
        errores.email = 'Email es obligatorio.'
    } else if (!validator.isEmail(valores.email)) {
        errores.email = 'El email no es valido.'
    }

    return errores;
}

export default function CorreoFormModal({ onSubmitCallback, initialForm, setShow }) {
    const formik = useFormik({
        initialValues: initialForm,
        validate,
        onSubmit: values => {
            onSubmitCallback(values);
            setShow(false);
        },
    });

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit} autoComplete="off" >
                <Row>
                    <Col>

                        <Form.Group control="email">
                            <Form.Label htmlFor="email">E-mail</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                id="email"
                                placeholder="e-mail"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.email && formik.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.email && <p> {formik.errors.email}</p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Button
                            variant="primary btn-block"
                            type="submit"
                            disabled={!formik.dirty}
                        >
                            Guardar
                        </Button>
                    </Col>
                </Row>

            </Form>

        </Container >

    )
}
