import { useFormik } from "formik";
import validator from 'validator';
import { Button, Col, Container, Form, Row } from "react-bootstrap";



const validate = valores => {
    let errores = {};

    if (validator.isEmpty(valores.direccion || '')) {
        errores.direccion = "Dirección es requerida"
    }

    if (validator.isEmpty(valores.altura || '')) {
        errores.altura = "Altura es requerida"
    }

    if (valores.pais?.id < 0) {
        errores.pais = "Debe seleccionar un país"
    }

    if (valores.provincia?.id < 0) {
        errores.provincia = "Debe seleccionar una privincia"
    }

    if (valores.localidad?.id < 0) {
        errores.localidad = "Debe seleccionar una localidad"
    }
    return errores;
}

const options = data => {
    return data.map((e) => (
        <option kew={e.id}
            value={e.id}
            label={e.nombre} />
    )
    );

}



export default function DireccionParticularModal({ onSubmitCallback, initialForm, paises, provincias, localidades, changeSelect, setShow }) {
    const formik = useFormik({
        initialValues: initialForm,
        validate,

        onSubmit: values => {
            onSubmitCallback(values);
            setShow(false);
        },

    });
    return (
        <Container>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="direccion">
                            <Form.Label>Calle</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="direccion"
                                value={formik.values.direccion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.direccion && formik.errors.direccion}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.direccion && <p> {formik.errors.direccion} </p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="altura">
                            <Form.Label>Nro</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="nro"
                                value={formik.values.altura}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.altura && formik.errors.altura}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.altura && <p> {formik.errors.altura} </p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="piso">
                            <Form.Label>Piso</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Piso"
                                value={formik.values.piso}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="dto">
                            <Form.Label>Departamento</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Departamento"
                                value={formik.values.dto}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.dto && formik.errors.dto} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="pais.id">
                            <Form.Label>País</Form.Label>
                            <Form.Control
                                as="select"
                                name="pais.id"
                                id="pais.id"
                                value={formik?.values?.pais?.id}
                                onBlur={formik.handleBlur}
                                onChange={e => {
                                    changeSelect(e);
                                    formik.handleChange(e);
                                    formik.setFieldValue("provincia.id", "-1");
                                    formik.setFieldValue("localidad.id", "-1");
                                }}
                                isInvalid={formik.touched.pais && formik.errors.pais}
                            >
                                {options(paises)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.pais && <p> {formik.errors.pais} </p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="provincia">
                            <Form.Label>Provincia</Form.Label>
                            <Form.Control
                                as="select"
                                name="provincia.id"
                                id="provincia"
                                value={formik?.values?.provincia?.id}
                                onBlur={formik.handleBlur}
                                onChange={e => {
                                    changeSelect(e);
                                    formik.handleChange(e);
                                    formik.setFieldValue("localidad.id", "-1")
                                }}
                                isInvalid={formik.touched.provincia && formik.errors.provincia}
                            >
                                <option kew="-1" value="-1">Seleccione una provincia</option>
                                {options(provincias)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.provincia && <p> {formik.errors.provincia} </p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="localidad">
                            <Form.Label>Localidad</Form.Label>
                            <Form.Control
                                as="select"
                                name="localidad.id"
                                id="localidad"
                                value={formik?.values?.localidad?.id}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.localidad && formik.errors.localidad}
                            >
                                <option kew="-1"
                                    value="-1"  >
                                    Seleccione una localidad
                                </option>
                                {options(localidades)}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.localidad && <p> {formik.errors.localidad} </p>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>


                {/* <Row>
                    <Col>
                        <SelectList tile="" url={PAISES} handleChange={ } />
                    </Col>
               </Row>*/}
                <Button
                    variant="primary btn-block"
                    type="submit"
                    disabled={!formik.dirty}
                >Guardar</Button>


            </Form>

        </Container >

    )
}
