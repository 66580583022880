import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer';
import { logoutUser } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { config } from "../../helpers/constantes";

export default function TimeOut() {
  const timeout = 1000 * 60 * config.timeOutMinutos;
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState('Events Emitted on Leader');
  const [leader, setLeader] = useState(true);
  const dispatch = useDispatch();

  const handleOnActive = () => dispatch(logoutUser());
  const handleOnIdle = () => setLastEvent('idle');

  const {
    /*  reset,
      pause,
      resume,*/
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    isIdle,
    isLeader
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true
    }
  })

  /* const handleReset = () => reset()
   const handlePause = () => pause()
   const handleResume = () => resume()*/

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
      setLeader(isLeader())
    }, 1000)
  }, [])

  return (
    <>

    </>
  )
}
