const routers = {
  root: "/",
  principal: "/principal",
  inscripcionesMaterias: "/inscripciones/materias",
  inscripcionesFinales: "/inscripciones/finales",
  consultasNotas: "/consultas/notas",
  consultaMaterias: "/consultas/materias",
  finalesHistorico: "/consultas/finalesHistorico",
  consultaFechasExamenes: "/consultas/fechasExamenes",
  tramite: "/tramites",
  pagos: "/pagos",
  miCuenta: "/miCuenta",
  blanqueoClave: "/clave/blanqueo/:codigo?",
  blanqueoClaveSinParametro: "/clave/blanqueo/ ",
  recuperoClave: "/clave/recupero/",

  constaciaDeInscripcionCurso: "/constaciaDeInscripcionCurso",
  constaciaDeInscripcionExamen: "/constaciaDeInscripcionExamen",
  consultaCatalogo: "/consultaCatalogo",
  historico: "/historico",
  renovar: "/renovar",
  certificadoExamen: "/certificadoExamen",

  administrativo: { listadoPagos: "/listadoPagos" },

  accesoDenegado: "/accesoDenegado",
  e404: "*",
};

export default routers;
