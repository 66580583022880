import { AgGridReact } from "ag-grid-react";
import {
  onFirstDataRendered,
  dateComparator,
  exportSeparator,
  AG_GRID_LANGUAGE_ES,
  filterParams,
  currencyFormatter,
  timeStamp,
  defaultColDef,
} from "../../../helpers/tablaConfig";

import { Button } from "react-bootstrap";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import { useEffect, useState, useMemo } from "react";

const incialData = [
  {
    id: 0,
    materia: { descred: "", descripcion: "" },
    fechaMesa: "",
    horaExamen: "",
    profesores: "",
  },
];
export default function InscripcionFinalesTable({
  data,
  accion,
  btnIcon,
  btnVariant,
  btnTitle,
  ocultar,
}) {
  const [gridApi, setGridApi] = useState([]);
  const [gridColumnApi, setGridColumnApi] = useState([]);
  const [tiempoImpresion, setTiempoImpresion] = useState(null);

  const gridOptions = useMemo(() => {
    return {
      flex: 1,
      wrapText: true,
      sortable: true,
      resizable: true, // permite cambiar el acho de la columna
      unSortIcon: true,
      filter: true,
      floatingFilter: false,
      animateRows: true,
    };
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onBtExport = () => {
    exportSeparator(gridApi);
  };

  useEffect(() => {
    setTiempoImpresion(timeStamp());
  }, []);

  const btnInscripcion = (params) => {
    return (
      <Button
        style={{ height: 25, lineHeight: 0.5 }}
        onClick={() => accion({ mesaSelected: params })}
        variant={btnVariant}
        data-toggle="tooltip"
        data-placement="top"
        title={btnTitle}
        disabled={params.data.disabled}
      >
        {btnIcon} {params.data.btnText}{" "}
      </Button>
    );
  };

  let filterParamsEstado = {
    filterType: "text",
    type: "equals",
    filter: "DISPONIBLE",
  };
  const columnDef = [
    {
      headerName: "",
      field: "accion",
      minWidth: 150,
      maxWidth: 300,
      sortable: false,
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      cellRendererFramework: (params) => btnInscripcion(params),
    },
    {
      headerName: "Mesa",
      field: "id",
      minWidth: 100,
      maxWidth: 200,
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Matéria",
      field: "materia.materiaDesc",
      minWidth: 200,
      maxWidth: 500,
      cellStyle: { textAlign: "right", "white-space": "normal" },
      autoHeight: true,
    },
    {
      headerName: "Importe",
      field: "importe",
      minWidth: 100,
      maxWidth: 150,
      cellStyle: { textAlign: "right" },
      cellRenderer: currencyFormatter,
      hide: ocultar,
    },
    {
      headerName: "Fecha",
      field: "fechaMesa",
      minWidth: 150,
      maxWidth: 500,
      cellStyle: { textAlign: "center" },
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      comparator: dateComparator,
    },
    {
      headerName: "Hora",
      field: "horaExamen",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Profesor(es)",
      field: "profesores",
      minWidth: 300,
      maxWidth: 700,
      cellStyle: { textAlign: "left", "white-space": "normal" },
      autoHeight: true,
    },
  ];

  return (
    <div>
      <div id="notasGrid" className="ag-theme-material" style={{ height: 300 }}>
        <AgGridReact
          rowData={data}
          onGridReady={onGridReady}
          pagination={true}
          defaultColDef={defaultColDef}
          paginationPageSize={10}
          localeText={AG_GRID_LANGUAGE_ES}
          onFirstDataRendered={onFirstDataRendered}
          columnDefs={columnDef}
          animateRows={true}
          resizable={true}
        />
      </div>
    </div>
  );
}
