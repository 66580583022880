import React, { useState } from "react";
import { Form, Button, Col, Row, Spinner } from "react-bootstrap";

export default function SignInForm({
  errors,
  onSubmitCallback,
  loading,
  recuerarClave,
  certificadoExamen,
}) {
  const [usernameUdemm, setUsernameUdemm] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("DNI");

  const submitForm = (e) => {
    e.preventDefault();
    onSubmitCallback({ usernameUdemm, password, type });
  };

  return (
    <Form onSubmit={submitForm} autoComplete="off">
      <Row>
        <Col>
          <Form.Group control="username">
            <Form.Label>Tipo Documento</Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="CI"> C.I.</option>
              <option value="DNI"> D.N.I.</option>
              <option value="LC"> L.C.</option>
              <option value="LE"> L.E.</option>
              <option value="Otro"> Otro</option>
              <option value="PAS"> Pas</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group control="usernameUdemm">
            <Form.Label>Nro. de Documento</Form.Label>
            <Form.Control
              type="text"
              value={usernameUdemm}
              onChange={(e) => setUsernameUdemm(e.target.value)}
              placeholder="Ingrese su Nro. de Documento"
              //required
              isInvalid={errors.usernameUdemm}
            />
            <Form.Control.Feedback type="invalid">
              {errors.usernameUdemm}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group control="password">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese su Clave"
              //required
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          {loading ? (
            <>
              <Button variant="primary btn-block" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Validando...
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary btn-block" type="submit">
                Ingresar
              </Button>
            </>
          )}
        </Col>
      </Row>
      <hr />

      <Button
        className="me-3"
        variant="btn btn-outline-info btn-block"
        // href={routers.recuperoClave}
        onClick={recuerarClave}
      >
        Recupera clave
      </Button>
      <Button
        variant="btn btn-outline-info btn-block"
        // href={routers.recuperoClave}
        onClick={certificadoExamen}
      >
        Verificar certificado examen
      </Button>
    </Form>
  );
}
