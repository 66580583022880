import {Container, Row, Col, Card, Image} from "react-bootstrap";
import logo from "../../assets/logo.png"

export default function CarreraPage() {
  return (
    <Container className="mt-5 ">
      <Row>
        <Col>
          <Card className="shadow p-3">
              <Row>
                  <Col className="text-center">
                      <Image src={logo} rounded className="img-fluid" alt="400px"/>
                  </Col>
              </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
