import { SET_ERRORES, SET_INFO, SET_SUCCESS } from "../actions/types";

const initialState = {};

export default function (state = initialState, { type, payload }) {

  switch (type) {
    case SET_ERRORES:
      return {
        menssage: payload,
      };
    case SET_SUCCESS:
      return {
        menssage: payload,
      };
    case SET_INFO:
      return {
        menssage: payload,
      };
    default:
      return state = initialState;
  }
}
