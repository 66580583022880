import { SET_CARRERAS_ALUMNO } from "./types";

export const carreraAlumnoSeleccionada = ( carrera ) => {
    return {  
        type: SET_CARRERAS_ALUMNO,
        payload: carrera
    };
}

export const cleanCarrera = () => dispatch => {

    dispatch(carreraAlumnoSeleccionada({
        alumnoPrograma: null,
        descripcion: "",
        fechaBaja: null,
    }));
}
