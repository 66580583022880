import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Card } from "react-bootstrap";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";
import {
  INICIO_TRAMITE_ENDPOINT,
  INICIO_TRAMITE_ENDPOINT_MATERIAS,
  INICIO_TRAMITE_ENDPOINT_MATERIAS_TRAMITE,
  INICIO_TRAMITE_TIPO_ENDPOINT,
} from "../../helpers/endpoints";
import Tabla from "./InicioTramitesTabla";
import Form from "./ModalTramiteForm";
import { helpHttp } from "../../helpers/helpHttp";
import { isObjEmpty } from "../../helpers/helpers";
import { setMessageAction } from "../../actions/messageActions";
import swal from "sweetalert2";

let initialForm = {
  tipoTramite: "",
  observaciones: "",
  datosAdicionales: "",
  isCertificadoMinisterio: false,
  materias: [{}],
  tipoTramiteSelect: {},
};

export default function InicioTramite() {
  const [errorForm, setErrorForm] = useState({});
  const [tramitesIniciados, setTramitesIniciados] = useState(null);
  const [tipoTramite, setTipoTramite] = useState([]);
  const [show, setShow] = useState(false);
  const [dataMateria, setDataMateria] = useState(null);
  const dispatch = useDispatch();
  const carreraSect = useSelector((state) => state.carr.carreraSect);
  const edit = useRef(true);

  useEffect(() => {
    carreraSect && getTramitesHistoricos();
  }, [carreraSect]);

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const inicializarFormulario = (data = {}) => {
    initialForm = {
      tipoTramite: isObjEmpty(data) ? "" : data.tipoTramiteSelect.valor,
      meaning: isObjEmpty(data) ? null : data.tipoTramiteSelect.meaning,
      observaciones: data.observaciones,
      datosAdicionales: data.datosAdicionales,
      isCertificadoMinisterio: data.isCertificadoMinisterio,
    };
  };

  const validarForm = (form) => {
    const errors = {};
    setErrorForm(errors);

    if (validator.isEmpty(form.tipoTramite)) {
      errors.tipoTramite = "Debe seleccionar un tipo de tramite";
    }

    return errors;
  };

  const getTramitesHistoricos = () => {
    helpHttp()
      .get(INICIO_TRAMITE_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => setTramitesIniciados(resp.data))
      .catch((error) => mensajeSistema(error.response.data.message, "ERROR"));
  };

  const tipostramites = () => {
    setDataMateria(null);
    inicializarFormulario();
    edit.current = true;

    helpHttp()
      .get(INICIO_TRAMITE_TIPO_ENDPOINT + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => setTipoTramite(resp.data))
      .catch((error) => mensajeSistema(error.response.data.message, "ERROR"));
  };

  const cargarMaterias = () => {
    helpHttp()
      .get(INICIO_TRAMITE_ENDPOINT_MATERIAS + "/" + carreraSect?.alumnoPrograma)
      .then((resp) => setDataMateria(resp.data))
      .catch((error) => mensajeSistema(error.response.data.message, "ERROR"));
  };

  const cargarMateriasSeleccionadas = (numeroTramite) => {
    helpHttp()
      .get(INICIO_TRAMITE_ENDPOINT_MATERIAS_TRAMITE + "/" + numeroTramite)
      .then((resp) => setDataMateria(resp.data))
      .catch((error) => mensajeSistema(error.response.data.message, "ERROR"));
  };

  const infoTramite = (importe) => {
    if (importe != 0) {
      swal.fire({
        title: `El trámite solicitado ha generado una deuda`,
        text: `Para finalizar la solicitud, debe abonar $${importe}. 
      Si el mismo no es saldado en los proximos 30 días, el trámite se eliminará de manera automática.`,
        icon: "info",
        buttons: true,
        dangerMode: true,
        toast: true,
      });
    }
  };

  const onSubmitCallback = (form) => {
    let errors = validarForm(form);
    if (!isObjEmpty(errors)) {
      setErrorForm(errors);
      return;
    }
    setDataMateria(null);
    setShow(!show);
    setTipoTramite(null);

    helpHttp()
      .post(INICIO_TRAMITE_ENDPOINT + "/" + carreraSect?.alumnoPrograma, {
        data: form,
      })
      .then((resp) => {
        setTramitesIniciados([resp.data, ...tramitesIniciados]);

        infoTramite(resp.data.saldo);

        mensajeSistema(
          "Se genero el tramite: " + resp.data.numeroTramite,
          "success"
        );
      })
      .catch((error) => mensajeSistema(error.response.data.message, "ERROR"));
  };

  const deleteRow = (id) => {
    swal
      .fire({
        title: `¿Quiere borrar el trámite ${id}?`,
        text: "Una vez eliminado, ¡no podrá recuperar recuperar el tramite! ",
        icon: "question",
        iconHtml: "؟",
        buttons: true,
        dangerMode: true,
        confirmButtonText: `Eliminar`,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          helpHttp()
            .del(INICIO_TRAMITE_ENDPOINT + "/" + id)
            .then((resp) => {
              setTramitesIniciados((tramitesIniciados) =>
                tramitesIniciados.filter((t) => t.numeroTramite != resp.data)
              );
              mensajeSistema("Tramite: " + resp.data + " borrado", "success");
            })
            .catch((error) =>
              mensajeSistema(error.response.data.message, "ERROR")
            );
        }
      });
  };

  const handleVer = (data) => {
    inicializarFormulario(data);
    edit.current = false;
    if (data.codTipo === "PROGRAMA") {
      cargarMateriasSeleccionadas(data.numeroTramite);
    }
    setShow(true);
  };

  return (
    <div>
      <Card className="shadow m-5 p-3">
        <div className="text-center">
          <Button
            variant="primary"
            disabled={!tramitesIniciados}
            onClick={() => {
              tipostramites();
              setShow(true);
            }}
          >
            Iniciar Trámite
          </Button>
          <hr />
          <Tabla
            data={tramitesIniciados}
            deleteRow={deleteRow}
            handleVer={handleVer}
          />
        </div>
      </Card>

      {
        <Modal
          size="lg"
          centered
          show={show}
          onHide={() => setDataMateria(null) || setShow(false)}
          backdrop="static"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Inicio de Trámite
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              tipoTramite={tipoTramite}
              onSubmitCallback={onSubmitCallback}
              initialForm={initialForm}
              errors={errorForm}
              cargarMaterias={cargarMaterias}
              dataMateria={dataMateria}
              edit={edit}
            />
          </Modal.Body>
        </Modal>
      }
    </div>
  );
}
