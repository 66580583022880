import {Card, Container} from "react-bootstrap";
import {useState, useEffect} from "react";
import {HISTORICO_INSCRIPCION_FINAL_ENDPOINT} from "../../../helpers/endpoints";
import {useSelector, useDispatch} from "react-redux";
import {setMessageAction} from "../../../actions/messageActions";
import {helpHttp} from "../../../helpers/helpAxios";
import InscripcionFinalesTable from "./InscripcionFinalesTable";
import axios from "axios";


export default function HistoricoInscricionFinales() {
    const [mesasFinales, setMesasFinales] = useState(null);
    const carreraSect = useSelector((state) => state.carr.carreraSect);
    const dispatch = useDispatch();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const mensajeSistema = (message, tipo) => {
        dispatch(setMessageAction({
            type: tipo,
            mensaje: message ? message : "Es este momento no podemos procesar la solicitud. Intente mas tarde"
        }));
    };



    const getMateriasIncripcion = () => {
        helpHttp().get(HISTORICO_INSCRIPCION_FINAL_ENDPOINT + "/" + carreraSect.alumnoPrograma, {source}).then((resp) => {
            setMesasFinales(resp.data);
        }).catch((error) => {
            setMesasFinales([]);
            mensajeSistema(error.message, "ERROR");
        });
    };



    useEffect(() => {
        setMesasFinales(null);
        carreraSect && getMateriasIncripcion();
return
    }, [carreraSect]);

    return (
        <Card className="shadow m-5 p-3">
            <InscripcionFinalesTable data={mesasFinales}/>
        </Card>
    );


}
