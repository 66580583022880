
import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Card, Spinner } from "react-bootstrap";
import Telefono from "./Telefono";

export default function TelefonoList({ telefonos, onSubmitCallback }) {

  return (
    <div>
      <div className="mt-2">
        <Card className="border-primary shadow">
          <div className="card-header">
            <h3 className="panel-title">Teléfonos</h3>
          </div>
          <div className="panel-body">
            <Container>

              {(!telefonos) ?

                (<Spinner animation="grow" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>)
                :
                <>
                  {
                    telefonos.map((t,i) => (
                      t.tipo === "Particular 1" ?
                        <Telefono key={i} titulo={"Particular"} telefono={t} onSubmitCallback={onSubmitCallback} /> :
                        <Telefono key={i} titulo={"Celular"} telefono={t} onSubmitCallback={onSubmitCallback} />
                    ))
                  }
                </>
              }

            </Container>
          </div>

        </Card>

      </div>

    </div>
  )
}

