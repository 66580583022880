import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  notifyError,
  isObjEmpty,
  notifySuccess,
  notifyWarning,
  notifyInfo,
} from "../helpers/helpers";

export default function MensajesSistema() {
  const { menssage } = useSelector((state) => state.msg);

  useEffect(() => {
    console.log("Mensajes", menssage);
    if (menssage != undefined && !isObjEmpty(menssage)) {
      switch (menssage.type) {
        case "success":
          notifySuccess(menssage.mensaje);
          break;
        case "ERROR":
          notifyError(menssage.mensaje);
          break;
        case "info":
          notifyInfo(menssage.mensaje);
          break;
        case "warning":
          notifyWarning(menssage.mensaje);
          break;
        default:
          console.info("Mensajes tipo no encontrado", menssage.type);
          notifyError(menssage.mensaje);
          break;
      }
    }
  }, [menssage]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
}
