import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import alumnoCarreraReducer from './reducers/alumnoCarreraReducer';
//Si existe index.js no hace falta declarar, perfiero convinar los reducer aca para que me quede menos archivos
//import rootReducer from './reducers';
import authReducer from './reducers/authReducer';
import mensajeSistemaReducer from './reducers/mensajeSistemaReducer';

const middleware = [thunk];

const rootReducer = combineReducers({
    auth: authReducer,
    carr: alumnoCarreraReducer,
    msg:  mensajeSistemaReducer,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;