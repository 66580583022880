import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setMessageAction } from "../../actions/messageActions";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CERTIFICADO } from "../../helpers/endpoints";

const initialForm = {
  codigo: "",
  legajo: "",
  token: "",
};

export default function CertificadoExamen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [res, setRes] = useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dynamicAction = "CertificadoExamen";
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Ejecutar recaptcha aún no disponible");
      return;
    }

    const result = await executeRecaptcha(dynamicAction);
    return result;
    // Do whatever you want with the token
  }, [executeRecaptcha, dynamicAction]);

  // si quiero una validacion mejor mirar
  // https://javascript.tutorialink.com/formik-yup-password-strength-validation-with-react/

  const validationSchema = Yup.object({
    codigo: Yup.string()
      .trim()
      .min(8, "El código de verificacion debe tener 8 caracteres.")
      .required("El código de verificación es obligatorio."),
    legajo: Yup.string()
      .matches(/^[0-9]+$/, "Solo digitos.")
      .min(1, "El legajo ingresado no es valido.")
      .required("El legajo es obligatorio."),
  });

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit: (values) => {
      onSubmitCallback(values);
      // setShow(false);
    },
  });

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const onSubmitCallback = async (values) => {
    values.token = await handleReCaptchaVerify();
    const params = { codigo: values.codigo, legajo: values.legajo };
    console.log("params ", params);

    axios
      .get(CERTIFICADO, { params })
      .then((resp) => {
        setRes(resp.data);
        console.log(res);
      })
      .catch((err) => {
        mensajeSistema(err.response.data.message, "ERROR");
      });
  };
  useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }
  }, [executeRecaptcha, dynamicAction]);

  return (
    <Container className="mt-5">
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Col>
            <Card className="mt-2 text-center" border="primary">
              <Card.Header as="h5">Validar certificado examen</Card.Header>
              <Card.Body>
                <Form.Group control="legajo">
                  <Form.Label htmlFor="legajo">Legajo</Form.Label>
                  <Form.Control
                    type="number"
                    name="legajo"
                    id="legajo"
                    min="1"
                    maxLength="20"
                    placeholder="Ingrese en número de legajo"
                    value={formik.values.legajo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.legajo && formik.errors.legajo}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.touched.legajo && <p> {formik.errors.legajo}</p>}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group control="codigo">
                  <Form.Label htmlFor="codigo">Código verificación</Form.Label>
                  <Form.Control
                    type="text"
                    name="codigo"
                    id="codigo"
                    placeholder="El código debe tener 8 caracteres"
                    maxLength="8"
                    value={formik.values.codigo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.codigo && formik.errors.codigo}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.touched.codigo && <p> {formik.errors.codigo}</p>}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary btn-block"
                  type="submit"
                  disabled={!formik.dirty}
                >
                  Validar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mt-2 " border="primary">
              <Card.Header as="h5" className="text-center">
                Certificado Examen
              </Card.Header>
              <Card.Body>
                <Row className="mb-2">
                  <Col md={2} sm={12}>
                    <b>Estudiante:</b>
                  </Col>
                  <Col md={10} sm={12}>
                    {res.estudiante}
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={2} sm={12}>
                    <b>Materia:</b>
                  </Col>
                  <Col md={10} sm={12}>
                    {res.descripcion}
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={2} sm={12}>
                    <b>Tipo examen:</b>
                  </Col>
                  <Col md={10} sm={12}>
                    {res.tipoEvaluacion}
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={2} sm={12}>
                    <b>Fecha examen:</b>
                  </Col>
                  <Col md={2} sm={12}>
                    {res.fecha}
                  </Col>

                  <Col md={2} sm={12}>
                    <b>Hora desde:</b>
                  </Col>
                  <Col md={2} sm={12}>
                    {res.horaDesde}
                  </Col>

                  <Col md={2} sm={12}>
                    <b>Hora hasta:</b>
                  </Col>
                  <Col md={2} sm={12}>
                    {res.horaHasta}
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={2} sm={12}>
                    <b>Docente: </b>
                  </Col>
                  <Col md={10} sm={12}>
                    {res.docente} ({res.rol})
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
