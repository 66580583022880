import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { helpHttp } from "../../helpers/helpAxios";
import { RECUPERAR_CLAVE } from "../../helpers/endpoints";
import { setMessageAction } from "../../actions/messageActions";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import validator from "validator";
import { useHistory } from "react-router-dom";
import routers from "../../helpers/routers";

const initialForm = {
  email: "",
  token: "",
};

const validate = (valores) => {
  let errores = {};

  if (validator.isEmpty(valores.email)) {
    errores.email = "Email es obligatorio.";
  } else if (!validator.isEmail(valores.email)) {
    errores.email = "El email no es valido.";
  }

  return errores;
};

export default function RecuperoClave() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inabilitado, setInabilitado] = useState(true);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [noOfVerifications, setNoOfVerifications] = useState(0);
  const [dynamicAction, setDynamicAction] = useState("blanqueClave");
  const [actionToChange, setActionToChange] = useState("");
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const result = await executeRecaptcha(dynamicAction);
    setToken(result);

    // Do whatever you want with the token
  }, [executeRecaptcha, dynamicAction]);

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const onSubmitCallback = async (values) => {
    setInabilitado(false);
    await handleReCaptchaVerify();
    values.token = token;
    await helpHttp()
      .put(RECUPERAR_CLAVE, {
        data: values,
      })
      .then(() => {
        mensajeSistema("Se realizo en envío con éxito", "success");
        history.push("/");
      })
      .catch((error) => {
        formik.resetForm();
        mensajeSistema(error.message, "ERROR");
      })
      .finally(() => setInabilitado(true));
  };

  useEffect(() => {
    if (loggedIn) {
      history.push(routers.principal);
    }
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
      setNoOfVerifications((noOfVerifications) => noOfVerifications + 1);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);

  const handleCommitAction = useCallback(() => {
    setDynamicAction(actionToChange);
  }, [actionToChange]);

  const formik = useFormik({
    initialValues: initialForm,
    validate,
    onSubmit: (values) => {
      onSubmitCallback(values);
    },
  });

  const btnHabilitado = (valor) => {
    if (!valor) return !valor;

    return !inabilitado;
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Col sm="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Card className="mt-2 text-center" border="primary">
              <Card.Header as="h5">Blanqueo clave</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" control="email">
                      <Form.Label htmlFor="FormEmail">
                        Ingrese su mail institucional para recuperar la clave{" "}
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="email"
                        id="email"
                        disabled={!inabilitado}
                        placeholder="usuario@udemm.edu.ar"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.email && formik.errors.email}
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.touched.email && (
                          <p className="text-left"> {formik.errors.email}</p>
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="btn btn-primary btn-block"
                      type="submit"
                      disabled={btnHabilitado(formik.dirty)}
                    >
                      {!inabilitado && (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {inabilitado ? "Recuperar" : "Procesando..."}
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      variant="btn btn-outline-info btn-block"
                      type="button"
                      onClick={() => history.push("/")}
                      disabled={!inabilitado}
                    >
                      Volver
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
