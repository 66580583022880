import { AgGridReact } from "ag-grid-react";
import {
  gridOptions,
  onFirstDataRendered,
  AG_GRID_LANGUAGE_ES,
  filterParams,
  currencyFormatter,
  dateComparator,
  exportSeparator,
} from "../../helpers/tablaConfig";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { BsFillTrashFill, BsEye } from "react-icons/bs";

export default function InicioTramitesTabla({ data, deleteRow, handleVer }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onBtExport = () => {
    const params = {
      columnKeys: [
        "numeroTramite",
        "fechaRecepcion",
        "tipoTramiteSelect.meaning",
        "estado",
        "sectorDestino",
        "saldo",
      ],
    };
    exportSeparator(gridApi, params);
  };

  const handleClick = (t) => {
    deleteRow(t.data.numeroTramite);
  };

  const inscripcion = (params) => {
    return (
      <>
        <Button
          id={params.data.numeroTramite}
          style={{
            height: 25,
            lineHeight: 0.5,
            marginRight: 5,
          }}
          onClick={() => handleClick(params)}
          disabled={params.data.estado !== "INICIADO"}
          variant="btn btn-success btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Baja tramite"
        >
          <BsFillTrashFill />
        </Button>

        <Button
          id={params.data.numeroTramite}
          style={{
            height: 25,
            lineHeight: 0.5,
          }}
          onClick={() => handleVer(params.data)}
          disabled={params.data.tipo !== "WEB"}
          variant="btn btn-success btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Ver tramite ingresado"
        >
          <BsEye />{" "}
        </Button>
      </>
    );
  };

  const columnDef = [
    {
      headerName: "",
      field: "",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: {
        textAlign: "center",
      },
      wrapText: true,
      autoHeight: true,
      sortable: false,
      resizable: true, // permite cambiar el acho de la columna
      unSortIcon: false,
      filter: false,
      floatingFilter: false,
      cellRendererFramework: (params) => inscripcion(params),
      hide: false,
    },

    {
      headerName: "Trámite Nro.",
      field: "tramiteAgrup",
      minWidth: 100,
      maxWidth: 200,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      headerName: "Fecha Inicio",
      field: "fechaRecepcion",
      minWidth: 150,
      maxWidth: 150,
      cellStyle: {
        textAlign: "center",
      },
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      comparator: dateComparator,
    },
    {
      headerName: "Importe Tramite",
      field: "saldo",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: currencyFormatter,
    },
    {
      headerName: "Tipo",
      field: "tipoTramiteSelect.meaning",
      minWidth: 600,
      maxWidth: 600,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      headerName: "Estado",
      field: "estado",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      headerName: "Sector",
      field: "sectorDestino",
      minWidth: 250,
      maxWidth: 250,
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Button
          onClick={() => onBtExport()}
          disabled={!(data && data.length > 0)}
          style={{ fontWeight: "bold" }}
          className="bt btn-info mb-2"
        >
          Exportar CSV
        </Button>
      </div>

      <div id="notasGrid" className="ag-theme-material" style={{ height: 500 }}>
        <AgGridReact
          rowData={data}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onGridReady={onGridReady}
          localeText={AG_GRID_LANGUAGE_ES}
          onFirstDataRendered={onFirstDataRendered}
          columnDefs={columnDef}
        />
      </div>
    </div>
  );
}
