import axios from "axios";
import { LOGIN_ENDPOINT } from "../helpers/endpoints";
import { SET_CURRENT_USER } from "./types";
import jwt_decode from "jwt-decode";
import setAuthToken from "../helpers/setAuthToken";

export const loginUser = (userData) => (dispatch) => {
  const credenciales = Buffer.from(
    "angularapp" + ":" + "12345",
    "utf8"
  ).toString("base64");
  const params = new URLSearchParams();
  params.set("grant_type", "password");
  params.set("username", userData.username);
  params.set("password", userData.password);

  return new Promise((resolve, reject) => {
    console.clear();
    axios
      .post(LOGIN_ENDPOINT, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + credenciales,
        },
      })
      .then((response) => {
        const authorization = response.data.access_token;

        localStorage.setItem("jwtToken", authorization);

        setAuthToken(authorization);

        const decoded = jwt_decode(authorization);

        const user = {
          lastName: decoded.apellido,
          firstName: decoded.nombre,
          authorities: decoded.authorities,
          sub: decoded.user_name,
          authorities: decoded.authorities,
        };

        if (!user.authorities.includes("ROLE_ALUMNO")) {
          reject(new Error("No tiene permisos para ingresar al sistema"));
        } else {
          dispatch(setCurrentUser({ user, loggedIn: true }));

          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setCurrentUser = ({ user, loggedIn, authorities }) => {
  return {
    type: SET_CURRENT_USER,
    payload: { user, loggedIn, authorities },
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");

  setAuthToken(false);

  dispatch(
    setCurrentUser({
      user: {},
      loggedIn: false,
      authorities: [],
    })
  );
};
