import { Container, Row, Col, Card, Spinner } from "react-bootstrap";



export default function LugarNacimiento({ lugarNacimiento }) {



  return (

    <div>
      <Card className="border-primary shadow mt-2">
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="card-header">
              <h3 className="panel-title">Lugar de nacimiento</h3>
            </div>
          </div>
          <div className="panel-body">
            <Container>
              {(!lugarNacimiento) ?
                (<Spinner animation="grow" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>)
                :
                <>
                  <Row className="mt-3 mb-3 text-left">
                    <Col>
                      País: <b>{lugarNacimiento?.nombrePais}</b>
                    </Col>
                    <Col>
                      Provincia: <b>{lugarNacimiento?.nombreProvincia}</b>
                    </Col>
                    <Col>
                      Localidad: <b>{lugarNacimiento?.nombreLocalidad}</b>
                    </Col>
                  </Row>
                </>
              }
            </Container>
          </div>
        </div>
      </Card>



    </div>
  )
}
