import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import SignInForm from "./SignInForm";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { isObjEmpty } from "../../helpers/helpers";
import { loginUser } from "../../actions/authActions";
import { useHistory } from "react-router-dom";
import routers from "../../helpers/routers";

export default function SignIn() {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //const location = useLocation();

  useEffect(() => {
    if (loggedIn) {
      /*history.push(location.state ? location.state.from : "/principal");*/
      history.push("/principal");
    }
  });

  const login = ({ usernameUdemm, password, type }) => {
    const errors = {};
    setErrors(errors);

    if (validator.isEmpty(usernameUdemm)) {
      errors.usernameUDemm = "El usuario no puede estar vacío";
    }

    if (validator.isEmpty(password)) {
      errors.password = "La contraseña no puede estar vacia";
    }

    if (!isObjEmpty(errors)) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const username = type + "_" + usernameUdemm;

    dispatch(loginUser({ username, password }))
      .then((response) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          setErrors({ auth: "Usuario o contraseña incorrectos" });
        } else {
          setErrors({
            auth: error?.response
              ? error.response.data.message
              : "Es este momento no podemos procesar la solicitud. Intente mas tardes",
          });
        }
      });
  };
  const recuerarClave = () => {
    history.push(routers.recuperoClave);
  };
  const certificadoExamen = () => {
    history.push(routers.certificadoExamen);
  };
  return (
    <Container className="mt-5">
      <Row>
        <Col sm="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <Card body>
            {errors.auth && <Alert variant="danger">{errors.auth}</Alert>}
            <h3>Iniciar sesión alumnos</h3>
            <hr />
            <SignInForm
              errors={errors}
              onSubmitCallback={login}
              loading={loading}
              recuerarClave={recuerarClave}
              certificadoExamen={certificadoExamen}
            ></SignInForm>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
