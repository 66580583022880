import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { gridOptions, onFirstDataRendered, AG_GRID_LANGUAGE_ES } from '../../../helpers/tablaConfig';
import { useState } from 'react';


export default function IncripcionesMateriasTable({ materias }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const columnDef = [
    {
      headerName: "Curso",
      field: "descripcion",
      minWidth: 300,
      maxWidth: 700,
      cellStyle: {textAlign: 'left','white-space': 'normal'},
      autoHeight: true
    },
    {
      headerName: "Horario",
      field: "diaSemana",
      minWidth: 200,
      maxWidth: 500,
      cellStyle: {textAlign: 'left','white-space': 'normal'},
      autoHeight: true
    },
    {
      headerName: "Año",
      field: "anioCarrera",
      minWidth: 75,
      maxWidth: 150,
      sort: 'desc',
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: "Profesores",
      field: "docentesCurso",
      minWidth: 300,
      maxWidth: 700,
      cellStyle: {textAlign: 'left','white-space': 'normal'},
      autoHeight: true
    }
  ];

  return (
  

    <div className="ag-theme-material"
      style={{ height: 600 }} >
      

  -    <AgGridReact
        rowData={materias}
        localeText={AG_GRID_LANGUAGE_ES}
        pagination={true}
        gridOptions={gridOptions}
        paginationPageSize={10}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        resizable={true}
        animateRows={true}
        columnDefs={columnDef}
      ></AgGridReact>

    </div>
    
  );
}
