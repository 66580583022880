import React from "react";
import { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import routers from "../helpers/routers";

import { MdOutgoingMail, MdPlace, MdPhone, MdFacebook } from "react-icons/md";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="text-center text-lg-start bg-light text-muted">
      <section className="footerBody">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <Navbar.Brand as={NavLink} to={routers.root}>
                  <img src="../assets/logo-udemm.png" />
                </Navbar.Brand>
              </h6>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Udemm</h6>
              <p>
                <a
                  href="https://www.portalalumnos.udemm.edu.ar"
                  className="text-reset"
                >
                  Alumnos
                </a>
              </p>
              <p>
                <a
                  href="https://www.portaldocentes.udemm.edu.ar"
                  className="text-reset"
                >
                  Profesores
                </a>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Redes</h6>
              <p>
                <a
                  href="https://www.facebook.com/udemmuniversidadprivada"
                  className="text-reset"
                >
                  <MdFacebook /> Facebook
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/udemmuniversidad"
                  className="text-reset"
                >
                  <AiFillInstagram /> Instagram
                </a>
              </p>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <MdPlace /> Av. Rivadavia 2258, CABA. Argentina
              </p>
              <p>
                <MdOutgoingMail /> inscripciones@udemm.edu.ar
              </p>
              <p>
                <MdPhone /> (+54) 011-4953-9000
              </p>
              <p>
                <MdPhone /> (+54) 011-4952-4700
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="footerLast text-center p-4">
        Copyright © {year} - UdeMM Universidad Privada
      </div>
    </footer>
  );
}
