import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import {
    gridOptions,
    onFirstDataRendered,
    AG_GRID_LANGUAGE_ES,
    filterParams,
    currencyFormatter,
    dateComparator,
} from "../../helpers/tablaConfig";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { Button } from "react-bootstrap";
import { formateCurrency } from "../../helpers/helpers";


export default function PagoCuota({ data, realizarPago = {} }) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };


    const columnDef = [


        {
            headerName: "Concepto",
            field: "concepto",
            minWidth: 600,
            maxWidth: 800,
            cellStyle: { textAlign: "left" },
        },
        {
            headerName: "Importe",
            field: "cuentaCorriente.importeConcepto",
            minWidth: 100,
            maxWidth: 200,
            cellStyle: { textAlign: "right" },
            cellRenderer: currencyFormatter,
        },
        {
            headerName: "Mes",
            field: "detalleDeudasDTO.mes",
            minWidth: 50,
            maxWidth: 200,
            cellStyle: { textAlign: "right" },
        },
        {
            headerName: "Año",
            field: "detalleDeudasDTO.anio",
            minWidth: 50,
            maxWidth: 200,
            cellStyle: { textAlign: "right" },
        },



    ];

    return (
        <div className="container mt-5">
            <div id="notasGrid" className="ag-theme-material" style={{ height: 300 }}>
                <AgGridReact
                    rowData={data.detalleDeudasDTO}
                    pagination={true}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    localeText={AG_GRID_LANGUAGE_ES}
                    onFirstDataRendered={onFirstDataRendered}
                    columnDefs={columnDef}
                />
            </div>
            <Button
                variant="primary btn-block"
                type="submit"
                disabled={(data.totalDeuda || 0) <= 0}
                onClick={() => realizarPago(data.totalDeuda)}>
                Pagar deuda: {formateCurrency.format(data.totalDeuda || 0)}
            </Button>

        </div>
    );
}