import {GridApi} from "ag-grid-community";


export const defaultColDef = {
    flex: 1,
    wrapText: true,
    sortable: true,
    resizable: true, // permite cambiar el acho de la columna
    unSortIcon: true,
    filter: true,
    floatingFilter: false,
    animateRows: true
};

export const gridOptions = {
   // defaultColDef
};

export const autoSizeAll = (skipHeader) => {
    let allColumnIds = [];
    gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
    });

    gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
};

const onPageSizeChanged = (newPageSize) => {
    let value = document.getElementById("page-size").value;
    console.log(Number(value));
    GridApi.paginationSetPageSize(Number(value));
};

export const pageSizeSelect = () => {
    <div className="example-header">
        Tamaño pagina:
        <select onChange={
                () => onPageSizeChanged()
            }
            id="page-size">
            <option value="10"
                selected={true}>
                10
            </option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
        </select>
    </div>;
};

// filto de fecha
export const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        let dateAsString = cellValue;
        if (dateAsString == null) 
            return -1;
        
        let dateParts = dateAsString.split("/");
        let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
    minValidYear: 2000
};

// formato de moneda
export const currencyFormatter = (params) => {
    let formateCurrency = new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2
    });
    return formateCurrency.format(params.value);
};

// ordena por fecha
export const dateComparator = (date1, date2) => {
    let date1Number = monthToComparableNumber(date1);
    let date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
};

function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    let yearNumber = date.substring(6, 10);
    let monthNumber = date.substring(3, 5);
    let dayNumber = date.substring(0, 2);
    return yearNumber * 10000 + monthNumber * 100 + dayNumber;

}

export function timeStamp(date){

    let _date;
    if (date === undefined || date === null){
        _date = new Date();
    }else{
        _date = date;
    }
    let year = _date.getFullYear().toString();
    let month = _date.getMonth().toString().padStart(2,'0');
    let day = _date.getDate().toString().padStart(2,'0');
    let hora = _date.getHours().toString();
    let min = _date.getMinutes().toString();
    let sec = _date.getSeconds().toString();
    let mil = _date.getMilliseconds().toString();
    return year+month+day+hora+min+sec+mil;

}
export const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
};

export const AG_GRID_LANGUAGE_ES = {
    // Set Filter
    selectAll: "Seleccionar todo",
    selectAllSearchResults: "Seleccionar todos los resultados de busqueda",
    searchOoo: "Buscar ...",
    blanks: "(Blanco)",
    noMatches: "No hay resultados",

    // Number Filter & Text Filter
    filterOoo: "Filtrar...",
    equals: "Igual",
    notEqual: "No igual",
    empty: "Elegir uno",

    // Number Filter
    lessThan: "menor que",
    greaterThan: "mayor que",
    lessThanOrEqual: "menor o igual que",
    greaterThanOrEqual: "mayor o igual que",
    inRange: "rango",
    inRangeStart: "desde",
    inRangeEnd: "hasta",

    // Text Filter
    contains: "Contiene",
    notContains: "No contiene",
    startsWith: "Comienza con",
    endsWith: "Termina con",

    // Date Filter
    dateFormatOoo: "yyyy-mm-dd",

    // Filter Conditions
    andCondition: "AND",
    orCondition: "OR",

    // Filter Buttons
    applyFilter: "Apply",
    resetFilter: "Reset",
    clearFilter: "Clear",
    cancelFilter: "Cancel",

    // Filter Titles
    textFilter: "Text Filter",
    numberFilter: "Number Filter",
    dateFilter: "Date Filter",
    setFilter: "Set Filter",

    // Side Bar
    columns: "Columns",
    filters: "Filters",

    // columns tool panel
    pivotMode: "Pivot Mode",
    groups: "Row Groups",
    rowGroupColumnsEmptyMessage: "Drag here to set row groups",
    values: "Values",
    valueColumnsEmptyMessage: "Drag here to aggregate",
    pivots: "Column Labels",
    pivotColumnsEmptyMessage: "Drag here to set column labels",

    // Header of the Default Group Column
    group: "Group",

    // Other
    loadingOoo: "Cargando...",
    noRowsToShow: "No se encontaron Filas",
    enabled: "Activo",

    // Menu
    pinColumn: "Pin Column",
    pinLeft: "Pin Left",
    pinRight: "Pin Right",
    noPin: "No Pin",
    valueAggregation: "Value Aggregation",
    autosizeThiscolumn: "Autosize This Column",
    autosizeAllColumns: "Autosize All Columns",
    groupBy: "Group by",
    ungroupBy: "Un-Group by",
    resetColumns: "Reset Columns",
    expandAll: "Expand All",
    collapseAll: "Close All",
    copy: "Copy",
    ctrlC: "Ctrl+C",
    copyWithHeaders: "Copy With Headers",
    paste: "Paste",
    ctrlV: "Ctrl+V",
    export: "Export",
    csvExport: "CSV Export",
    excelExport: "Excel Export",

    // Enterprise Menu Aggregation and Status Bar
    sum: "Suma",
    min: "Min",
    max: "Max",
    none: "Nada",
    count: "Cantidad",
    avg: "Promedio",
    filteredRows: "Filtrado",
    selectedRows: "Seleccionado",
    totalRows: "Total Filas",
    totalAndFilteredRows: "Filas",
    more: "Mas",
    to: "to",
    of: "of",
    page: "Pagina",
    nextPage: "Siguiente",
    lastPage: "Ultima Pagina",
    firstPage: "Primera Pagina",
    previousPage: "Anterior",

    // Pivoting
    pivotColumnGroupTotals: "Total",

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
    pivotChart: "Pivot Chart",
    chartRange: "Chart Range",

    columnChart: "Column",
    groupedColumn: "Grouped",
    stackedColumn: "Stacked",
    normalizedColumn: "100% Stacked",

    barChart: "Bar",
    groupedBar: "Grouped",
    stackedBar: "Stacked",
    normalizedBar: "100% Stacked",

    pieChart: "Pie",
    pie: "Pie",
    doughnut: "Doughnut",

    line: "Line",

    xyChart: "X Y (Scatter)",
    scatter: "Scatter",
    bubble: "Bubble",

    areaChart: "Area",
    area: "Area",
    stackedArea: "Stacked",
    normalizedArea: "100% Stacked",

    histogramChart: "Histogram",

    // Charts
    pivotChartTitle: "Pivot Chart",
    rangeChartTitle: "Range Chart",
    settings: "Settings",
    data: "Data",
    format: "Format",
    categories: "Categories",
    defaultCategory: "(None)",
    series: "Series",
    xyValues: "X Y Values",
    paired: "Paired Mode",
    axis: "Axis",
    navigator: "Navigator",
    color: "Color",
    thickness: "Thickness",
    xType: "X Type",
    automatic: "Automatic",
    category: "Category",
    number: "Number",
    time: "Time",
    xRotation: "X Rotation",
    yRotation: "Y Rotation",
    ticks: "Ticks",
    width: "Width",
    height: "Height",
    length: "Length",
    padding: "Padding",
    spacing: "Spacing",
    chart: "Chart",
    title: "Title",
    titlePlaceholder: "Chart title - double click to edit",
    background: "Background",
    font: "Font",
    top: "Top",
    right: "Right",
    bottom: "Bottom",
    left: "Left",
    labels: "Labels",
    size: "Size",
    minSize: "Minimum Size",
    maxSize: "Maximum Size",
    legend: "Legend",
    position: "Position",
    markerSize: "Marker Size",
    markerStroke: "Marker Stroke",
    markerPadding: "Marker Padding",
    itemSpacing: "Item Spacing",
    itemPaddingX: "Item Padding X",
    itemPaddingY: "Item Padding Y",
    layoutHorizontalSpacing: "Horizontal Spacing",
    layoutVerticalSpacing: "Vertical Spacing",
    strokeWidth: "Stroke Width",
    offset: "Offset",
    offsets: "Offsets",
    tooltips: "Tooltips",
    callout: "Callout",
    markers: "Markers",
    shadow: "Shadow",
    blur: "Blur",
    xOffset: "X Offset",
    yOffset: "Y Offset",
    lineWidth: "Line Width",
    normal: "Normal",
    bold: "Bold",
    italic: "Italic",
    boldItalic: "Bold Italic",
    predefined: "Predefined",
    fillOpacity: "Fill Opacity",
    strokeOpacity: "Line Opacity",
    histogramBinCount: "Bin count",
    columnGroup: "Column",
    barGroup: "Bar",
    pieGroup: "Pie",
    lineGroup: "Line",
    scatterGroup: "X Y (Scatter)",
    areaGroup: "Area",
    histogramGroup: "Histogram",
    groupedColumnTooltip: "Grouped",
    stackedColumnTooltip: "Stacked",
    normalizedColumnTooltip: "100% Stacked",
    groupedBarTooltip: "Grouped",
    stackedBarTooltip: "Stacked",
    normalizedBarTooltip: "100% Stacked",
    pieTooltip: "Pie",
    doughnutTooltip: "Doughnut",
    lineTooltip: "Line",
    groupedAreaTooltip: "Area",
    stackedAreaTooltip: "Stacked",
    normalizedAreaTooltip: "100% Stacked",
    scatterTooltip: "Scatter",
    bubbleTooltip: "Bubble",
    histogramTooltip: "Histogram",
    noDataToChart: "No data available to be charted.",
    pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
    chartSettingsToolbarTooltip: "Menu",
    chartLinkToolbarTooltip: "Linked to Grid",
    chartUnlinkToolbarTooltip: "Unlinked from Grid",
    chartDownloadToolbarTooltip: "Download Chart",

    // ARIA
    ariaHidden: "hidden",
    ariaVisible: "visible",
    ariaChecked: "checked",
    ariaUnchecked: "unchecked",
    ariaIndeterminate: "indeterminate",
    ariaColumnSelectAll: "Toggle Select All Columns",
    ariaInputEditor: "Input Editor",
    ariaDateFilterInput: "Date Filter Input",
    ariaFilterInput: "Filter Input",
    ariaFilterColumnsInput: "Filter Columns Input",
    ariaFilterValue: "Filter Value",
    ariaFilterFromValue: "Filter from value",
    ariaFilterToValue: "Filter to value",
    ariaFilteringOperator: "Filtering Operator",
    ariaColumn: "Column",
    ariaColumnGroup: "Column Group",
    ariaRowSelect: "Press SPACE to select this row",
    ariaRowDeselect: "Press SPACE to deselect this row",
    ariaRowToggleSelection: "Press Space to toggle row selection",
    ariaRowSelectAll: "Press Space to toggle all rows selection",
    ariaToggleVisibility: "Press SPACE to toggle visibility",
    ariaSearch: "Search",
    ariaSearchFilterValues: "Search filter values",

    // ARIA LABEL FOR DIALOGS
    ariaLabelColumnMenu: "Column Menu",
    ariaLabelCellEditor: "Cell Editor",
    ariaLabelDialog: "Dialog",
    ariaLabelSelectField: "Select Field",
    ariaLabelTooltip: "Tooltip",
    ariaLabelContextMenu: "Context Menu",
    ariaLabelSubMenu: "SubMenu",
    ariaLabelAggregationFunction: "Aggregation Function"
};

export const exportSeparator = (gridApi, params = {}) => {
    let _params = {
        ...params,
        columnSeparator: ';'
    }

    gridApi.exportDataAsCsv(_params);
}
