import { AgGridReact } from "ag-grid-react";
import { gridOptions, onFirstDataRendered, AG_GRID_LANGUAGE_ES, exportSeparator } from '../../../helpers/tablaConfig';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useState } from "react";
import { Button } from "react-bootstrap";

export default function FechaExamenesTabla({  data }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
 
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const onBtExport = () => {
    exportSeparator(gridApi);
  };

  const columnDef = [
   
    {
      headerName: "Código materia",
      field: "descred",
      minWidth: 100,
      maxWidth: 200,
      cellStyle: { textAlign: 'left' }
    },
    {
      headerName: "Materia",
      field: "descripcionMateria",
      minWidth: 300,
      maxWidth: 500,
      cellStyle: {textAlign: 'left','white-space': 'normal'},
      autoHeight: true
    },
    {
      headerName: "1° Parcial",
      field: "fechaParcial1",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: "2° Parcial",
      field: "fechaParcial2",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: "Recuperatorio",
      field: "fechaRecuperatorio",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: "Final",
      field: "fechaFinal",
      minWidth: 150,
      maxWidth: 200,
      cellStyle: { textAlign: 'center' }
    },
  ];

  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => onBtExport()} style={{ fontWeight: "bold" }} className="bt btn-info mb-2">Exportar CSV</Button>
      </div>
      <div id="fechaExamenes" className="ag-theme-material" style={{ height: 500 }}>
        <AgGridReact
          rowData={data}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={10}
          onGridReady={onGridReady}
          localeText={AG_GRID_LANGUAGE_ES}
          onFirstDataRendered={onFirstDataRendered}
          columnDefs={columnDef}
          resizable={true}
          animateRows={true}
        />
      </div>
    </div>

  );
}
